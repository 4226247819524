import gql from 'graphql-tag';
import { ItemsFragment } from './fragments';

export const GET_POPULAR_VIDEOS = gql`
    query GetPopularVideos($limit: Int!, $offset: Int) {
        videoList: videos(offset: $offset, limit: $limit, mostPopular: true) {
            ...baseVideoFields
        }
    }
    ${ItemsFragment}
`;
