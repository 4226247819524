import React, { ReactFragment, ReactNode } from 'react';
import AdSlot from 'src/components/Ads/AdSlot';
import { IVideoDisplayAd } from 'src/types/ads';
import styles from './displayAd.module.scss';

const generateVideoListWithDisplayAds = ({
    videoList,
    displayAd,
}: {
    videoList: ReactFragment | ReactNode[];
    displayAd?: IVideoDisplayAd;
}) => {
    if (videoList && Array.isArray(videoList)) {
        let adIndex = 0;
        const excludeDesktop =
            displayAd &&
            displayAd.exclude &&
            displayAd.exclude.includes('desktop');
        const excludeTablet =
            displayAd &&
            displayAd.exclude &&
            displayAd.exclude.includes('tablet');
        const excludeMobile =
            displayAd &&
            displayAd.exclude &&
            displayAd.exclude.includes('mobile');
        const videoComponents = videoList.map(
            (video: ReactNode, index: number) => {
                if (
                    displayAd &&
                    displayAd.place &&
                    displayAd.place.includes(index)
                ) {
                    adIndex++;
                    return [
                        !excludeDesktop && (
                            <AdSlot
                                id={
                                    adIndex === 1
                                        ? `${displayAd.type}`
                                        : `${displayAd.type}-${adIndex}`
                                }
                                className={`${styles.containerRelated} ${
                                    displayAd.type
                                } adLabel `}
                                key={`${displayAd.type}-${adIndex}-ad`}
                            />
                        ),
                        !excludeTablet && (
                            <AdSlot
                                id={
                                    adIndex === 1
                                        ? `${displayAd.type}-tab`
                                        : `${displayAd.type}-${adIndex}-tab`
                                }
                                className={`${styles.containerRelated} ${
                                    displayAd.type
                                }Tab adLabel`}
                                key={`${displayAd.type}-${adIndex}-tab-ad`}
                            />
                        ),
                        !excludeMobile && (
                            <AdSlot
                                id={
                                    adIndex === 1
                                        ? `${displayAd.type}-mob`
                                        : `${displayAd.type}-${adIndex}-mob`
                                }
                                className={`${styles.containerRelated} ${
                                    displayAd.type
                                }Mob adLabel`}
                                key={`${displayAd.type}-${adIndex}-mob-ad`}
                            />
                        ),
                        video,
                    ];
                } else {
                    return video;
                }
            }
        );
        return [videoComponents.slice(0, videoComponents.length)];
    }
    return [];
};

export default generateVideoListWithDisplayAds;
