import { IConfig } from './index';
import { production } from './production';

export const qa: IConfig = {
    adloader: {
        test: true,
        prebid: {
            display: {
                disable: production.adloader.prebid.display.disable,
                debug: production.adloader.prebid.display.debug,
            },
            video: {
                disable: production.adloader.prebid.video.disable,
                debug: production.adloader.prebid.video.debug,
                bids: [
                    {
                        bidder: production.adloader.prebid.video.bids[0].bidder,
                        params: {
                            placementId:
                                production.adloader.prebid.video.bids[0].params
                                    .placementId,
                        },
                    },
                ],
            },
        },
    },
    adUnit: production.adUnit,
    api: 'https://video-ws.qa.startsiden.no/v2/api',
    apiRest: 'https://video-ws.qa.startsiden.no/rest',
    adminApi:
        'https://admin-tjenester.startsiden.no/wp-json/wp/v2/pages?slug=panel-abctv-qa',
    facebookAppId: production.facebookAppId,
    host: 'https://qa.abcnyheter.no',
    basePath: production.basePath,
    logger: production.logger,
    player: {
        autoplay: production.player.autoplay,
    },
    tracking: {
        googleAnalytics: {
            ga: {
                id: production.tracking.googleAnalytics.ga.id,
            },
            ga4: {
                id: production.tracking.googleAnalytics.ga4.id,
            },
        },
        piano: {
            siteId: production.tracking.piano.siteId,
            persistedQueryId: production.tracking.piano.persistedQueryId,
            section: production.tracking.piano.section,
        },
        gtm: {
            id: 'GTM-PFQFDS2',
        },
        chartbeat: {
            id: production.tracking.chartbeat.id,
            domain: 'qa.abcnyheter.no',
        },
        parsely: {
            siteUrl: 'qa.abctv.abcnyheter.no',
        },
        kilkaya: {
            customerId: production.tracking.kilkaya.customerId,
        },
    },
    servicePaths: {
        startsidenPath: production.servicePaths.startsidenPath,
        abcNyheterPath: production.servicePaths.abcNyheterPath,
        searchPath: production.servicePaths.searchPath,
        tvGuidenPath: production.servicePaths.tvGuidenPath,
        abcGuiderPath: production.servicePaths.abcGuiderPath,
        abcTv: production.servicePaths.abcTv,
        piano: '//sandbox.piano.io/xbuilder/experience/load?aid=P8FwGVtbsu',
    },
    strossle: {
        url: production.strossle.url,
        id: {
            videoPageRelevant: production.strossle.id.videoPageRelevant,
        },
    },
    featureToggle: {
        displayAd: production.featureToggle.displayAd,
    },
    navigationCategories: production.navigationCategories,
};
