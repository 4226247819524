import { ApolloProvider } from '@apollo/react-components';
import React from 'react';
import { hydrate, render } from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import isBrowser from 'src/helpers/isBrowser';
import logger from 'src/services/Logger/isomorphicLogger';
import client from './apollo/client';
import App from './App';
import './index.scss';

const WrappedApp = () => {
    return (
        <ApolloProvider client={client}>
            <BrowserRouter>
                <HelmetProvider>
                    <App />
                </HelmetProvider>
            </BrowserRouter>
        </ApolloProvider>
    );
};

if (isBrowser()) {
    window.addEventListener('error', event => {
        const { message, lineno, error, filename, colno } = event;
        logger.error(message, { error, filename, lineno, colno });
    });
}

const renderMethod = !!(module as any).hot ? render : hydrate;
renderMethod(<WrappedApp />, document.getElementById('root'));
