import React, { Component, ReactNode } from 'react';
import { Link } from 'src/components/Link';
import pathGenerator from 'src/helpers/pathGenerator/pathGenerator';
import styles from './Error.module.scss';

interface IErrorProps {
    showHomeLink?: boolean;
}

class Error extends Component<IErrorProps> {
    public render(): ReactNode {
        return (
            <div className={styles.container}>
                <h1 className={styles.title}>Uupsi… </h1>
                <p className={styles.text}>Vi fant ikke det du lette etter</p>
                {this.props.showHomeLink && (
                    <p className={styles.text}>
                        Kanskje du vil gå til
                        <Link
                            className={styles.link}
                            to={pathGenerator.getFrontPath()}
                        >
                            {' '}
                            forsiden{' '}
                        </Link>
                    </p>
                )}
            </div>
        );
    }
}

export default Error;
