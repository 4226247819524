import gql from 'graphql-tag';

export const POST_LOGS = gql`
    mutation postLogs($body: body!) {
        publishedPost: publish(body: $body)
            @rest(
                type: "LogMessage"
                path: ""
                method: "POST"
                bodyKey: "body"
            ) {
            body
        }
    }
`;
