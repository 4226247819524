import isBrowser from 'src/helpers/isBrowser';
import Storage from './Storage';

const WATCHED_VIDEOS_KEY = 'abctv_watched_videos';

export interface IStorageAPI {
    getItem: () => void;
    setItem: () => void;
    removeItem: () => void;
}

const storageAPI: IStorageAPI = {
    getItem: () => null,
    setItem: () => null,
    removeItem: () => null,
};

const getLocalStorage = () => {
    try {
        return window.localStorage;
    } catch (e) {
        return storageAPI;
    }
};

const localStorageAPI = isBrowser() ? getLocalStorage() : storageAPI;
const localStorage = new Storage(localStorageAPI);

export { localStorage, WATCHED_VIDEOS_KEY };
