import gql from 'graphql-tag';
import { ItemsFragment } from './fragments';

export const GET_CATEGORY_POPULAR = gql`
    query GetCategoryPopular($limit: Int!, $site: String, $offset: Int) {
        videoList: videos(
            offset: $offset
            limit: $limit
            site: $site
            mostPopular: true
        ) {
            ...baseVideoFields
        }
    }
    ${ItemsFragment}
`;
