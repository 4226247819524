import React, { FunctionComponent } from 'react';

import LazyLoadImage from 'src/components/LazyLoadImage';
import time from 'src/helpers/time';
import styles from './Thumbnail.module.scss';

export interface IThumbnailProps {
    className?: string;
    imageUrl: string;
    duration?: number;
}

const Thumbnail: FunctionComponent<IThumbnailProps> = props => {
    const { duration, imageUrl, className } = props;

    return (
        <>
            <LazyLoadImage url={imageUrl} className={className} />
            {duration && (
                <span className={styles.duration} data-testid="duration">
                    {time.formatDuration(duration)}
                </span>
            )}
        </>
    );
};

export default Thumbnail;
