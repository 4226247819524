import slugify from 'src/helpers/slugify';
import {
    ITracker,
    ITrackEventParams,
    ITracking,
    ITrackPageViewParams,
} from 'src/types/tracking';

export default class Tracking implements ITracking {
    public trackers: ITracker[] = [];

    constructor(implementations: ITracker[]) {
        this.initTrackers(implementations);
    }

    public trackPageView(params: ITrackPageViewParams) {
        this.trackers.forEach((tracker: ITracker) => {
            tracker.trackPageView(params);
        });
    }

    public trackEvent(params: ITrackEventParams) {
        this.trackers.forEach(tracker => {
            tracker.trackEvent({
                ...params,
                action: slugify(params.action),
            });
        });
    }

    private initTrackers = (trackingImplementations: ITracker[]) => {
        this.trackers = trackingImplementations.map(
            implementation => implementation
        );
    };
}
