export interface IRecommendationsParameters {
    page: 1;
    device: 'desktop';
    format: 'json';
    url: string;
    clientId: string;
}

type TGetRecommendationsParamsVariant = 'pageUrl' | 'widget';

export type TGetRecommendationsParams = {
    [key in TGetRecommendationsParamsVariant]: string
};

export interface IStrosslePost {
    id: string;
    title: string;
    content: string;
    type: 'article' | 'ad';
    url: string;
    published?: string;
    view: string;
    image: {
        url: string;
    };
    t_click: string;
    t_imp: string;
    third_party: string;
}

export interface IGetRecommendationsResponse {
    uid: string;
    cid: string;
    t_load: string;
    t_imp: string;
    next_page: string;
    provided_by: {
        link: string;
        label: string;
    };
    posts: IStrosslePost[];
}

class GetRecommendations {
    public getParameters = ({
        pageUrl,
        widget,
    }: TGetRecommendationsParams): IRecommendationsParameters => {
        return {
            page: 1,
            device: 'desktop',
            format: 'json',
            url: pageUrl,
            clientId: widget,
        };
    };

    public parseResponse(response: IGetRecommendationsResponse) {
        const { posts = [] } = response;
        const ads = posts.filter(post => post.type === 'ad');

        return ads.map(post => {
            const { title, url, image, t_click, t_imp } = post;
            return { title, url, imageUrl: image.url, t_click, t_imp };
        });
    }
}

const getRecommendations = new GetRecommendations();

export default getRecommendations;
