import 'cross-fetch/polyfill';

import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { RestLink } from 'apollo-link-rest';
import getConfig from 'src/config';

const strossle = new ApolloClient({
    link: new RestLink({ uri: getConfig().strossle.url }),
    cache: new InMemoryCache(),
});

export default strossle;
