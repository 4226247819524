import React, {
    Component,
    FunctionComponent,
    MouseEvent,
    ReactNode,
} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import trackingService from 'src/services/Tracking';
import { ITrackEventParams, ITrackGA4EventParams } from 'src/types/tracking';

export interface ILinkProps {
    to: string;
    children?: ReactNode;
    className?: string;
    onClick?: (event: MouseEvent<HTMLAnchorElement>) => void;
    tracking?: ITrackEventParams;
    ga4Tracking?: ITrackGA4EventParams;
    isExternal?: boolean;
    title?: string;
    target?: '_blank' | '_self';
}

const ExternalLink: FunctionComponent<ILinkProps> = props => {
    const {
        to,
        onClick,
        className,
        children,
        title,
        target,
        ...restProps
    } = props;

    const isMailto = to.indexOf('mailto:') === 0;
    const externalLinkProps = {
        ...(!isMailto && {
            rel: 'noopener noreferrer',
            target: target || '_blank',
        }),
    };

    return (
        <a
            href={to}
            onClick={onClick}
            className={className}
            title={title}
            {...restProps}
            {...externalLinkProps}
        >
            {children}
        </a>
    );
};

const InternalLink: FunctionComponent<ILinkProps> = props => {
    const { to, onClick, className, children, title, ...restProps } = props;

    return (
        <RouterLink
            to={to}
            onClick={onClick}
            className={className}
            title={title}
            {...restProps}
        >
            {children}
        </RouterLink>
    );
};

export default class Link extends Component<ILinkProps, {}> {
    public render(): ReactNode {
        const { onClick, ...restProps } = this.props;
        const Tag = this.props.isExternal ? ExternalLink : InternalLink;

        return <Tag onClick={this.handleClick} {...restProps} />;
    }

    private handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
        const { ga4Tracking, onClick, tracking } = this.props;
        if (tracking) {
            trackingService.trackEvent({ ...tracking, ga4Tracking });
        }

        if (typeof onClick === 'function') {
            onClick(event);
        }
    };
}
