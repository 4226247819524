import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import VideoListContainer from 'src/components/VideoListContainer';
import CategoryContext from 'src/context/CategoryContext';
import { GET_RELATED_VIDEOS } from 'src/gql';
import pathGenerator from 'src/helpers/pathGenerator/pathGenerator';
import { IVideoDisplayAd } from 'src/types/ads';

import styles from './AsideVideoList.module.scss';

interface IVideoRouteProps
    extends RouteComponentProps<{
        categoryName: string;
        videoId: string;
        urlSafeTitle: string;
    }> {
    showLabel?: boolean;
}

const AsideVideoList = ({
    isMobile,
    queryLimit,
    displayAd,
    props,
}: {
    isMobile?: boolean;
    queryLimit: number;
    displayAd?: IVideoDisplayAd;
    props: IVideoRouteProps;
}) => {
    const { match, showLabel = true } = props;
    const { categoryName, videoId, urlSafeTitle } = match.params;

    const currentPath = pathGenerator.getVideoPath({
        categoryName,
        videoId,
        urlSafeTitle,
    });

    return (
        <aside
            className={`${
                isMobile ? styles.sidebarMobile : styles.sidebarDesktop
            } ${styles.sidebar}`}
        >
            <CategoryContext.Provider value={{ name: categoryName }}>
                <VideoListContainer
                    queryData={{
                        query: GET_RELATED_VIDEOS,
                        variables: {
                            limit: queryLimit,
                            providerId: videoId,
                            relevanceConfig: {
                                title: 5,
                            },
                        },
                    }}
                    videoListProps={{
                        title: 'Relaterte videoer',
                        listItemVariant: 'horizontal',
                        listType: 'vertical',
                        displayAd,
                        currentPath,
                        hideShowMore: !isMobile,
                        replaceChildrenWhenLoading: true,
                        showPublished: true,
                        showLabelBeforeData: showLabel,
                        tracking: {
                            action: 'related_feed',
                            category: 'abctv_recommended_videos',
                            ga4Tracking: {
                                event_name: 'abctv_recommended_videos',
                                event_details_1: 'related_feed',
                                event_details_2: 'video_box',
                            },
                        },
                    }}
                />
            </CategoryContext.Provider>
        </aside>
    );
};

export default AsideVideoList;
