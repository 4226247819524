import gql from 'graphql-tag';
import { ItemsFragment } from './fragments';

export const GET_CATEGORY_LATEST = gql`
    query GetCategoryLatest($limit: Int, $site: String, $offset: Int) {
        videoList: videos(offset: $offset, limit: $limit, site: $site) {
            ...baseVideoFields
        }
    }
    ${ItemsFragment}
`;
