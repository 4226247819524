import { FALLBACK_CATEGORY_NAME } from 'src/constants';
import pathGenerator from 'src/helpers/pathGenerator';

interface ICategory {
    name: string;
    urlSafeName: string;
    site: string;
    description: string;
    showInNavigation: boolean;
}

class CategoryProvider {
    private categories: ICategory[];

    constructor(categories: ICategory[]) {
        this.categories = categories;
    }

    public getNavigationCategories(): ICategory[] {
        return this.categories.filter(category => !!category.showInNavigation);
    }

    public getByUrlSafeName(urlSafeName: string): ICategory {
        const filtered = this.categories.filter(
            category => category.urlSafeName === urlSafeName
        );

        if (!filtered.length) {
            // tslint:disable-next-line: no-console
            console.warn(`Category not matched for path: ${urlSafeName}`);
            return this.getFallback();
        }

        return filtered[0];
    }

    public getBySite(site: string): ICategory {
        const filtered = this.categories.filter(
            category => category.site === site
        );

        if (!filtered.length) {
            // tslint:disable-next-line: no-console
            console.warn(`Category not matched for site: ${site}`);
            return this.getFallback();
        }

        return filtered[0];
    }

    public getFallback() {
        return this.categories.filter(
            category => category.name === FALLBACK_CATEGORY_NAME
        )[0];
    }

    public getRoutePath(urlSafeName: string): string {
        return pathGenerator.getCategoryPath({
            categoryName: urlSafeName,
        });
    }
}

const list: ICategory[] = [
    {
        name: FALLBACK_CATEGORY_NAME,
        urlSafeName: FALLBACK_CATEGORY_NAME,
        site: '',
        description: 'general description placeholder',
        showInNavigation: false,
    },
    {
        name: 'viralt',
        urlSafeName: 'viralt',
        site: 'sedenne',
        description: 'Videoene folk snakker om',
        showInNavigation: true,
    },
    {
        name: 'nyheter',
        urlSafeName: 'nyheter',
        site: 'abcnyheter',
        description: 'De siste videoene fra nyhetsbildet',
        showInNavigation: true,
    },
    {
        name: 'underholdning',
        urlSafeName: 'underholdning',
        site: 'startsiden',
        description: 'Underholdning, film og folk',
        showInNavigation: true,
    },
    {
        name: 'gjør det selv',
        urlSafeName: 'gjor-det-selv',
        site: 'kreativeideer',
        description: 'Nyttige og inspirerende tips og triks',
        showInNavigation: true,
    },
];

export default new CategoryProvider(list);
