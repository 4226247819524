export const imageProxyUrl = 'https://imaginary.abcmedia.no';

export const imageProxy = (url: string, size: number) => {
    if (!size) {
        return `${imageProxyUrl}/pipe?url=${encodeURIComponent(url)}`;
    }

    return `${imageProxyUrl}/resize?width=${size}&url=${encodeURIComponent(
        url
    )}`;
};

export const getSharingImageProxyUrl = (imageUrl: string): string => {
    return `${imageProxyUrl}/resize?width=2400&interlace=true&aspectratio=191:100&nocrop=false&url=${imageUrl}`;
};
