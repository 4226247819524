import logger from 'src/services/Logger/isomorphicLogger';

interface IScriptAttribute {
    name: string;
    value: string;
}

export interface ILoadScript {
    url: string;
    id: string;
    attributes: [IScriptAttribute] | [];
}

const createScript = ({ url, id, attributes }: ILoadScript) => {
    const script = document.createElement('script');
    script.setAttribute('id', id);
    script.setAttribute('src', url);

    attributes.forEach(attribute => {
        script.setAttribute(attribute.name, attribute.value);
    });

    return script;
};

export default function loadScript({ url, id, attributes = [] }: ILoadScript) {
    if (document.getElementById(id)) {
        return Promise.resolve();
    }
    const script = createScript({ url, id, attributes });
    document.getElementsByTagName('body')[0].appendChild(script);

    return new Promise<void>((resolve, reject) => {
        script.onload = () => resolve();
        script.onerror = () => reject(new Error(`Cannot load script: ${url}`));
    }).then(
        () => {
            return;
        },
        error => {
            logger.error(`Cannot load script: ${url}`, error);
        }
    );
}
