import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import logger from 'src/services/Logger/isomorphicLogger';
import Error from './Error';
import styles from './Error.module.scss';

interface IErrorBoundaryState {
    hasError: boolean;
}

class ErrorBoundary extends Component<
    RouteComponentProps,
    IErrorBoundaryState
> {
    public static getDerivedStateFromError(error: any) {
        return { hasError: true };
    }
    constructor(props: RouteComponentProps) {
        super(props);
        this.state = { hasError: false };
    }
    public componentDidUpdate(prevProps: RouteComponentProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({ hasError: false });
        }
    }

    public componentDidCatch(error: any, info: any) {
        // You can also log the error to an error reporting service
        logger.error('Error Boundary Catch', { error, info });
    }

    public render() {
        if (this.state.hasError) {
            return (
                <div className={styles.container}>
                    <Error showHomeLink />
                </div>
            );
        }
        return this.props.children;
    }
}

export default withRouter(ErrorBoundary);
