import getRecommendations, {
    IGetRecommendationsResponse,
    IRecommendationsParameters,
    TGetRecommendationsParams,
} from './GetRecommendations';
import tracking from './Tracking';

interface IMappedStrossleAd {
    title: string;
    url: string;
    imageUrl: string;
    t_click: string;
    t_imp: string;
}

export interface IStrossle {
    recommendationsParams: (
        params: TGetRecommendationsParams
    ) => IRecommendationsParameters;
    getRecommendationsParseResponse: (
        response: IGetRecommendationsResponse
    ) => IMappedStrossleAd[];
    tracking: (params: string) => void;
}

class Strossle implements IStrossle {
    public defaultParameters: {
        pageUrl?: string;
        widget?: string;
    } = {};

    constructor() {
        this.defaultParameters = {};
    }

    public recommendationsParams = (params: TGetRecommendationsParams) =>
        getRecommendations.getParameters(params);

    public getRecommendationsParseResponse = (
        response: IGetRecommendationsResponse
    ) => getRecommendations.parseResponse(response);

    public tracking = (params: string) => tracking.execute(params);
}

export default new Strossle();
