import loadScript from 'src/helpers/loadScript';
import logger from 'src/services/Logger/isomorphicLogger';
import { IPiano, IPianoConfig, ITrackPageViewParams } from 'src/types/tracking';

declare global {
    interface Window {
        tp: any;
    }
}

interface IK5AMeta {
    title: string;
    section: string;
    description: string;
    image: string;
    tags: string;
    type: string;
    publishtime: string;
    author?: string;
    modifiedtime?: string;
}

declare global {
    interface Window {
        k5aMeta?: IK5AMeta;
    }
}

export default class Piano implements IPiano {
    public config: IPianoConfig;
    constructor(config: IPianoConfig) {
        this.config = config;
        this.init();
        this.load().catch(error => {
            logger.error('Piano not loaded.', { error });
        });
    }

    public trackPageView(params: ITrackPageViewParams) {
        const { video, location } = params;
        try {
            if (video) {
                window.tp.push(['setTags', (video.tags || '').split(',')]);
                window.tp.push(['setContentAuthor', video.author]);
                const sectionMatch = location.pathname.match(
                    /^\/abctv\/([^\/]+)\//
                );
                if (sectionMatch && sectionMatch[1]) {
                    window.tp.push(['setContentSection', sectionMatch[1]]);
                }
            }
        } catch (error) {
            logger.error('Piano tracking error.', { error });
        }
    }

    public trackEvent(): void {
        return;
    }

    public init = (): void => {
        return;
    };

    private load = () => {
        return loadScript({
            url: this.config.url,
            id: 'piano-script',
            attributes: [
                {
                    name: 'async',
                    value: 'true',
                },
            ],
        });
    };
}
