import {
    ITracker,
    ITrackEventParams,
    ITrackPageViewParams,
} from 'src/types/tracking';

export interface IParselyConfig {
    siteUrl: string;
}

interface IParselyPreload {
    eventQueue: any[];
    loaded: boolean;
}

declare global {
    interface Window {
        PARSELY: {
            autotrack: boolean;
            beacon?: {
                trackPageView(event: any): () => void;
            };
            onload: () => void;
        };
    }
}

export default class Parsely implements ITracker {
    private config: IParselyConfig;
    private parselyPreload: IParselyPreload;

    constructor(config: IParselyConfig) {
        this.config = config;
        this.parselyPreload = { eventQueue: [], loaded: false };
        this.init();
    }

    public trackPageView({ title, urlref }: ITrackPageViewParams) {
        const event = {
            url: window.location.toString(),
            urlref,
            title,
            js: 1,
        };
        if (this.parselyPreload.loaded && window.PARSELY.beacon) {
            window.PARSELY.beacon.trackPageView(event);
        } else {
            this.parselyPreload.eventQueue.push(event);
        }
    }

    public trackEvent({ action, category, label, value }: ITrackEventParams) {
        if (!this.shouldTrackEvent(action)) {
            return;
        }
        const event = {
            url: window.location.toString(),
            urlref: window.location.toString(),
            action: `_${action}`,
            data: {
                category,
                label,
                value,
            },
        };

        if (this.parselyPreload.loaded && window.PARSELY.beacon) {
            window.PARSELY.beacon.trackPageView(event);
        } else {
            this.parselyPreload.eventQueue.push(event);
        }
    }

    private init = () => {
        window.PARSELY = {
            autotrack: false,
            onload: () => {
                this.parselyPreload.loaded = true;
                this.parselyPreload.eventQueue.forEach(event => {
                    if (window.PARSELY.beacon) {
                        window.PARSELY.beacon.trackPageView(event);
                    }
                });
            },
        };

        this.appendParselyScript(this.config.siteUrl);
    };

    private appendParselyScript(siteUrl: string) {
        const body = document.body;
        const script = document.createElement('script');

        script.id = 'parsely-cfg';
        script.src = `//cdn.parsely.com/keys/${siteUrl}/p.js`;
        script.setAttribute('data-parsely-site', siteUrl);
        body.appendChild(script);
    }

    private shouldTrackEvent(action: string) {
        return typeof action !== 'undefined';
    }
}
