import { useQuery } from '@apollo/react-hooks';
import { ILocalState } from 'src/types/types';

export default function useGraphqlQuery(query: string): ILocalState {
    const { data } = useQuery(query, {
        fetchPolicy: 'cache-only',
    }) as { data: ILocalState };
    try {
        return data;
    } catch (error) {
        return {};
    }
}
