import React, { ComponentType } from 'react';
import { TFeatures } from 'src/config';
import featureToggleHelpers from './helpers';

export const withFeatureToggle = (
    featureName: TFeatures,
    WrappedComponent: ComponentType<any>
) => {
    return function WithFeatureToggle(props: any) {
        return featureToggleHelpers.isFeatureActive(featureName) ? (
            <WrappedComponent {...props} />
        ) : null;
    };
};
