import gql from 'graphql-tag';
import { ItemsFragment } from './fragments';

export const GET_SEARCH_RESULTS = gql`
    query GetSearchResultsQuery($limit: Int!, $search: String!, $offset: Int) {
        videoList: videos(offset: $offset, search: $search, limit: $limit) {
            ...baseVideoFields
            page
            last
            limit
            offset
            count
        }
    }
    ${ItemsFragment}
`;
