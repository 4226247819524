import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { RouteComponentProps } from 'react-router-dom';

import { useQuery } from '@apollo/react-hooks';
import AsideVideoList from 'src/components/AsideVideoList';
import Loader from 'src/components/Loader';
import Video from 'src/components/Video';
import VideoListContainer from 'src/components/VideoListContainer';
import { GET_CATEGORY_LATEST, GET_URL, GET_VIDEO } from 'src/gql';
import urlProvider from 'src/helpers/urlProvider/urlProvider';

import AdSlot from 'src/components/Ads/AdSlot/AdSlot';
import displayAdStyles from 'src/components/VideoList/displayAd.module.scss';
import {
    GET_IS_FIRST_LOAD,
    GET_LAST_TRACK_PAGE_VIEW_URL,
} from 'src/gql/queries/@clientQueries';
import { getSharingImageProxyUrl } from 'src/helpers/imageProxy/imageProxy';
import { getOGTitle } from 'src/helpers/openGraph';
import useGraphqlQuery from 'src/helpers/readGraphqlQuery/useGraphqlQuery';
import { formatDateNumber, formatIsoDate } from 'src/helpers/time/time';
import writeGraphqlData from 'src/helpers/writeGraphqlData';
import CategoryProvider from 'src/services/CategoryProvider';
import tracking from 'src/services/Tracking';
import { IVideo } from 'src/types/video';
import styles from './Video.module.scss';

interface IVideoRouteProps
    extends RouteComponentProps<{
        categoryName: string;
        videoId: string;
        urlSafeTitle: string;
    }> {}

function VideoRoute(props: IVideoRouteProps) {
    const { location, match } = props;
    const { categoryName = 'nyheter', videoId } = match.params;
    const [videoToTrack, setVideoToTrack] = useState<IVideo>();
    const { urlRef } = useGraphqlQuery(GET_URL);
    const { isFirstLoad } = useGraphqlQuery(GET_IS_FIRST_LOAD);
    const { lastTrackPageViewUrl } = useGraphqlQuery(
        GET_LAST_TRACK_PAGE_VIEW_URL
    );
    const category = CategoryProvider.getByUrlSafeName(categoryName);
    const { site } = category;

    useEffect(() => {
        if (videoToTrack) {
            if (lastTrackPageViewUrl !== window.location.href) {
                setTimeout(() => {
                    tracking.trackPageView({
                        location: window.location,
                        title: videoToTrack.title,
                        video: videoToTrack,
                        urlref: (urlRef && urlRef[1]) || 'direct',
                        isFirstLoad,
                    });
                });
            }
            writeGraphqlData({ isFirstLoad: false });
            writeGraphqlData({ lastTrackPageViewUrl: window.location.href });
        }
    }, [videoToTrack]);

    const { loading, data, error } = useQuery(GET_VIDEO, {
        variables: {
            id: videoId,
        },
        notifyOnNetworkStatusChange: true,
    });

    if (error) {
        throw error;
    }

    const { video } = data;
    const firstVideo: IVideo = video && video.items && video.items[0];

    const renderVideoScript = () => {
        const scriptContent = JSON.stringify(
            {
                '@context': 'https://schema.org',
                '@type': 'VideoObject',
                name: firstVideo.title,
                description: firstVideo.summary,
                thumbnailUrl: firstVideo.imageUrl,
                uploadDate: formatDateNumber(firstVideo.published),
                contentUrl: firstVideo.content_url,
            },
            null,
            2
        );
        return <script type="application/ld+json">{scriptContent}</script>;
    };

    useEffect(() => {
        if (firstVideo && !loading) {
            setVideoToTrack(firstVideo);
        }
    }, [firstVideo]);

    return (
        <main>
            <div className={styles.container}>
                <div className={styles.mainVideo}>
                    {firstVideo && (
                        <Helmet
                            title={firstVideo.title}
                            meta={[
                                {
                                    name: 'description',
                                    content: firstVideo.summary,
                                },
                                {
                                    property: 'og:description',
                                    content: firstVideo.summary,
                                },
                                {
                                    property: 'og:title',
                                    content: getOGTitle(firstVideo.title),
                                },
                                {
                                    property: 'og:image',
                                    content: getSharingImageProxyUrl(
                                        firstVideo.imageUrl
                                    ),
                                },
                                {
                                    property: 'og:image:width',
                                    content: '1200',
                                },
                                {
                                    property: 'og:image:height',
                                    content: '628',
                                },
                                {
                                    property: 'og:url',
                                    content: urlProvider(location.pathname),
                                },
                                {
                                    name: 'og:type',
                                    content: 'video',
                                },
                                {
                                    name: 'parsely-type',
                                    content: 'post',
                                },
                                {
                                    name: 'parsely-title',
                                    content: firstVideo.title,
                                },
                                {
                                    name: 'parsely-image-url',
                                    content: firstVideo.imageUrl,
                                },
                                {
                                    name: 'parsely-pub-date',
                                    content: formatIsoDate(
                                        parseInt(`${firstVideo.published}`, 10)
                                    ),
                                },
                                {
                                    name: 'parsely-tags',
                                    content: firstVideo.tags,
                                },
                                {
                                    name: 'parsely-post-id',
                                    content: firstVideo.provider_id,
                                },
                                {
                                    name: 'parsely-section',
                                    content: categoryName,
                                },
                                {
                                    name: 'cXenseParse:pageclass',
                                    content: 'article',
                                },
                                ...firstVideo.tags.split(',').map(tag => ({
                                    name: 'og:tag',
                                    content: tag,
                                })),
                            ]}
                        >
                            {renderVideoScript()}
                        </Helmet>
                    )}
                    <Loader
                        containerClass="video"
                        listClass="vertical"
                        loaderCount={1}
                        loading={loading}
                        error={error}
                    >
                        {firstVideo && !loading && (
                            <Video
                                video={firstVideo}
                                className={styles.videoContainer}
                            />
                        )}
                    </Loader>
                    <div className={styles.asideConainerDesktop}>
                        <header>
                            <h4>Relaterte videoer</h4>
                        </header>
                        <AsideVideoList
                            isMobile={false}
                            queryLimit={26}
                            displayAd={{
                                type: 'rectangle',
                                place: [3],
                                exclude: ['mobile'],
                            }}
                            props={{ ...props, showLabel: false }}
                        />
                    </div>
                </div>
                <div className={styles.main}>
                    <div className={styles.asideConainerMobile}>
                        <AdSlot
                            id="topbanner-mob"
                            className={`topbannerMob adLabel ${
                                displayAdStyles.containerRelated
                            }`}
                        />
                        <AsideVideoList
                            isMobile={true}
                            queryLimit={6}
                            props={props}
                        />
                    </div>
                    <div className={styles.listContainer}>
                        <section className={styles.list}>
                            <VideoListContainer
                                queryData={{
                                    query: GET_CATEGORY_LATEST,
                                    variables: {
                                        limit: 12,
                                        site,
                                    },
                                }}
                                videoListProps={{
                                    title: 'Siste',
                                    listItemVariant: 'vertical_variant_a',
                                    listType: 'horizontal',
                                    tracking: {
                                        action: 'siste_feed',
                                        category: 'abctv_recommended_videos',
                                        ga4Tracking: {
                                            event_name:
                                                'abctv_recommended_videos',
                                            event_details_1: 'siste_feed',
                                            event_details_2: 'video_box',
                                        },
                                    },
                                }}
                            />
                        </section>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default VideoRoute;
