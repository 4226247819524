import React, { Component, ReactNode } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Error } from 'src/components/Error';
import writeGraphqlData from 'src/helpers/writeGraphqlData';
import withTracking from 'src/routes/withTracking';
import { ITracking } from 'src/types/tracking';
import styles from './Error.module.scss';

interface IErrorPageProps extends RouteComponentProps<{}> {
    trackingService: ITracking;
}

class ErrorPage extends Component<IErrorPageProps> {
    public componentWillUnmount(): void {
        writeGraphqlData({ hasError: false });
    }

    public render(): ReactNode {
        return (
            <main className={styles.container}>
                <Error showHomeLink />
            </main>
        );
    }
}

export const ErrorWithTracking = withTracking(ErrorPage);
export default ErrorPage;
