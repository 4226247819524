import React, { FC, useContext, useEffect } from 'react';
import { matchPath, RouteComponentProps, withRouter } from 'react-router';
import { withFeatureToggle } from 'src/components/FeatureToggle';
import getConfig from 'src/config';

import { withNoAdsHandler } from 'src/components/Ads/withNoAdsHandler';
import { IPrebidDisplayConfig } from 'src/config';
import routes, { routeNames } from 'src/routes';
import AdSlotProvider from 'src/services/AdSlotProvider';
import logger from 'src/services/Logger/isomorphicLogger';
import { IAdConfig, IAdLoaderConfig, IPrebidConfig } from 'src/types/ads';

import AbVariantContext from 'src/context/AbVariantContext';
import { GET_ABNAME } from 'src/gql';
import useGraphqlQuery from 'src/helpers/readGraphqlQuery';
import {
    categorySlots,
    frontSlots,
    videoSlots,
} from './slotsConfig/slotsConfig';

export let adloader: any;

declare const window: Window & {
    AdLoaderAds?: IAdLoaderConfig;
};

export const AdloaderComponent: FC<RouteComponentProps> = props => {
    useEffect(() => {
        if (adloader) {
            reinitAdloader();
        }
    }, [props]);

    useEffect(() => {
        loadLibs().then(adloaderLib => {
            adloader = adloaderLib.default;
            const currentRouteName = getCurrentRouteName();
            if (currentRouteName) {
                adloader.init(getAdConfig(currentRouteName));
            }
        });
    }, []);

    const { abVariant } = useContext(AbVariantContext);
    const { abName } = useGraphqlQuery(GET_ABNAME);

    const reinitAdloader = () => {
        // abc-adloader's lazy loading functionality is built on top of
        // window scroll events. This little delay help to make sure that
        // window can be programatically scrolled to top before
        // adloader's slot visibility observer is reinitialised
        setTimeout(() => {
            const currentRouteName = getCurrentRouteName();
            if (currentRouteName) {
                adloader.reinit(getAdConfig(currentRouteName));
            }
        }, 100);
    };

    const getCurrentRouteName = () => {
        const currentRoute = Object.values(routes).find(
            route => !!matchPath(props.location.pathname, route)
        );
        return currentRoute ? currentRoute.name : '';
    };

    const loadLibs = async () => {
        try {
            return await import(/* webpackChunkName: "abc-adloader" */
            /* webpackPrefetch: true */
            '@startsiden/abc-adloader' as any);
        } catch (error) {
            await logger.error(
                `Fetching adloader failed. Error: ${(error as any).toString()}`
            );
        }
    };

    const getSlot = (slot: IAdConfig) => {
        return AdSlotProvider(slot);
    };

    const getSlots = (pageType: string) => {
        if (!pageType) {
            return [];
        }

        const slotsByPageType = {
            [routeNames.VIDEO]: videoSlots.map(getSlot),
            [routeNames.CATEGORY]: categorySlots.map(getSlot),
            [routeNames.FRONT]: frontSlots.map(getSlot),
        };

        return slotsByPageType[pageType] || [];
    };

    const getGDPRConfig = () => {
        if (typeof window !== 'undefined' && window._sp_) {
            return {
                consentManagement: {
                    gdpr: {
                        cmpApi: 'iab',
                        defaultGdprScope: true,
                    },
                },
            };
        }
        return {};
    };

    const getPrebidConfig = (
        prebidConfig: IPrebidDisplayConfig
    ): { prebid: IPrebidConfig } => {
        const { disable, debug } = prebidConfig;
        return {
            prebid: {
                disablePrebid: disable,
                bidderSettings: {
                    standard: {
                        storageAllowed: true,
                    },
                },
                prebidSettings: {
                    ...getGDPRConfig(),
                    debug,
                    currency: {
                        adServerCurrency: 'NOK',
                        defaultRates: {
                            GBP: {
                                NOK: 11,
                            },
                            USD: {
                                NOK: 8,
                            },
                        },
                        granularityMultiplier: 1,
                    },
                    priceGranularity: {
                        buckets: [
                            {
                                increment: 0.1,
                                max: 200,
                                min: 0,
                            },
                        ],
                    },
                    userSync: {
                        filterSettings: {
                            iframe: {
                                bidders: ['ix', 'rubicon', 'appnexus'],
                                filter: 'include',
                            },
                        },
                        iframeEnabled: true,
                        syncDelay: 3000,
                        userIds: [
                            {
                                name: 'pubCommonId',
                            },
                        ],
                    },
                },
            },
        };
    };

    const getAdKeyValue = () => {
        const adloaderConfig = getConfig().adloader;
        const { keyValues } = adloaderConfig;

        if (abName && abVariant) {
            return {
                keyValues: {
                    ...keyValues,
                    abtest: `${abName}_${abVariant}`,
                },
            };
        } else {
            return { ...keyValues };
        }
    };

    const getAdConfig = (pageType: string) => {
        const adloaderAppConfig = getConfig().adloader;
        const prebidConfig: IPrebidDisplayConfig =
            adloaderAppConfig.prebid.display;
        const { test } = adloaderAppConfig;

        const adloaderConfig: IAdLoaderConfig = {
            ...getPrebidConfig(prebidConfig),
            targeting: {
                1: 'abcnyheter.no',
                3: 'abctv',
            },
            ...getAdKeyValue(),
            stopStickyWallpaperAd: 1000,
            test,
            slots: [...getSlots(pageType)],
        };

        window.AdLoaderAds = adloaderConfig;
        return window.AdLoaderAds;
    };
    return <></>;
};

export default withFeatureToggle(
    'displayAd',
    withNoAdsHandler(withRouter(AdloaderComponent))
);
