import { FunctionComponent, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

export const ScrollTop: FunctionComponent<RouteComponentProps> = props => {
    const { pathname } = props.location;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};

export default withRouter(ScrollTop);
