import gql from 'graphql-tag';
import { ItemsFragment } from 'src/gql/queries/fragments';

export const GET_PLAYLIST_VIDEO = gql`
    query GetPlayListVideos($limit: Int!, $id: String!) {
        videos: playlistVideos(id: $id, limit: $limit) {
            ...baseVideoFields
            items {
                id
                provider
                published
                summary: description
                tags
                site
                content_url: contentUrl
            }
        }
    }
    ${ItemsFragment}
`;
