import React, { useContext, useEffect, useRef } from 'react';
import CategoryProvider from 'src/services/CategoryProvider';

import { ITrackEventParams } from 'src/types/tracking';
import { TVideoListItemVariant } from 'src/types/video';
import { IVideoBase } from 'src/types/video';

import { Link } from 'src/components/Link/index';
import CategoryContext, { ICategoryContext } from 'src/context/CategoryContext';
import PlayListContext, { IPlayListContext } from 'src/context/PlayListContext';
import concatClassNames from 'src/helpers/concatClassNames';
import pathGenerator from 'src/helpers/pathGenerator';
import time from 'src/helpers/time';
import urlProvider from 'src/helpers/urlProvider';
import Thumbnail from './Thumbnail/index';
import styles from './VideoListItem.module.scss';

export interface IVideoListItemProps {
    videoItem: IVideoBase;
    onClick?: () => void;
    variant: TVideoListItemVariant;
    tracking?: ITrackEventParams;
    selected?: boolean;
    showPublished?: boolean;
}

function VideoListItem(props: IVideoListItemProps) {
    const { videoItem, variant, onClick, selected, showPublished } = props;
    const renderCommercialLabel = () => {
        return <div className={styles.advertiserLabel}>Annonsørinnhold:</div>;
    };
    const getPath = (
        categoryContext: ICategoryContext,
        playListContext: IPlayListContext
    ) => {
        const categoryName =
            categoryContext.name ||
            CategoryProvider.getBySite(videoItem.site).urlSafeName;

        return playListContext.id
            ? pathGenerator.getPlayListPath({
                  playListId: playListContext.id,
                  videoId: videoItem.provider_id,
                  urlSafeTitle: videoItem.urlSafeTitle,
              })
            : pathGenerator.getVideoPath({
                  categoryName,
                  videoId: videoItem.provider_id,
                  urlSafeTitle: videoItem.urlSafeTitle,
              });
    };

    const getTrackingConfig = (trackedPath: string) => {
        const { tracking } = props;

        if (typeof tracking === 'undefined') {
            return;
        }
        const { ga4Tracking, ...restTracking } = tracking;

        return {
            'data-ga4-event-details-1':
                (ga4Tracking && ga4Tracking.event_details_1) || undefined,
            'data-ga4-event-details-2':
                (ga4Tracking && ga4Tracking.event_details_2) || undefined,
            'data-ga4-event-name':
                (ga4Tracking && ga4Tracking.event_name) || undefined,
            'data-ga4-link-title':
                (ga4Tracking && ga4Tracking.link_title) || undefined,
            'data-ga4-link-url': trackedPath,
            'data-ga4-element-order':
                (ga4Tracking && ga4Tracking.element_order) || undefined,
            tracking: {
                ...restTracking,
                label: tracking.label || urlProvider(trackedPath),
                video: videoItem,
            },
        };
    };

    const myRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (selected && myRef.current && myRef.current.scrollIntoView) {
            myRef.current.scrollIntoView(true);
            window.scrollTo(0, 0);
        }
    }, [selected]);

    const advertiserClassName = videoItem.advertiser
        ? styles.hasAdvertiser
        : '';
    const selectedClassName = selected ? styles.selected : '';
    const category = useContext(CategoryContext);
    const playListId = useContext(PlayListContext);
    const path = getPath(category, playListId);

    return (
        <>
            {videoItem.advertiser &&
                variant === 'horizontal' &&
                renderCommercialLabel()}
            <Link
                className={concatClassNames(
                    styles.link,
                    styles[variant],
                    selectedClassName
                )}
                onClick={onClick}
                to={path}
                title={videoItem.title}
                {...getTrackingConfig(path)}
            >
                <div className={styles.thumbnailContainer} ref={myRef}>
                    <div className={styles.thumbnail}>
                        <Thumbnail
                            imageUrl={videoItem.imageUrl}
                            duration={videoItem.duration}
                            className={styles.backgroundImageContainer}
                        />
                    </div>
                </div>
                <div
                    className={concatClassNames(
                        styles.itemContent,
                        advertiserClassName
                    )}
                    data-testid="VideoListItemContent"
                >
                    {videoItem.advertiser &&
                        variant !== 'horizontal' &&
                        renderCommercialLabel()}

                    <h5 lang="nb-no" className={styles.title}>
                        {videoItem.title}
                    </h5>
                    {videoItem.advertiser && (
                        <div className={styles.advertiserName}>
                            {videoItem.advertiser}
                        </div>
                    )}
                    {showPublished && videoItem.published && (
                        <time className={styles.published}>
                            Publisert: {time.formatDate(videoItem.published)}
                        </time>
                    )}
                </div>
            </Link>
        </>
    );
}

export default VideoListItem;
