import gql from 'graphql-tag';
import { ItemsFragment } from './fragments';

export const GET_RELATED_VIDEOS = gql`
    query GetRelatedVideos(
        $providerId: String!
        $limit: Int!
        $relevanceConfig: relevanceConfig
        $watchedVideoIds: String
        $offset: Int
    ) {
        videoList: videos(
            providerId: $providerId
            limit: $limit
            mostRelevant: true
            relevanceConfig: $relevanceConfig
            watchedVideoIds: $watchedVideoIds
            promoted: true
            offset: $offset
        ) {
            ...baseVideoFields
            items {
                published
            }
        }
    }
    ${ItemsFragment}
`;
