import React, { Component, ReactNode } from 'react';
import VideoContent from 'src/components/VideoContent';
import ViprPlayerLazy from 'src/components/ViprPlayerLazy';
import { IVideo } from 'src/types/video';

export interface IVideoProps {
    video: IVideo;
    playListId?: string;
    className?: string;
}

class Video extends Component<IVideoProps, any> {
    public shouldComponentUpdate(nextProps: IVideoProps) {
        return this.props.video.provider_id !== nextProps.video.provider_id;
    }

    public render(): ReactNode {
        const { video, playListId, className } = this.props;

        return (
            <div className={className}>
                <ViprPlayerLazy
                    containerId="vipr-player"
                    video={{
                        ...video,
                        id: video.id.toString(),
                    }}
                    playListId={playListId || ''}
                />
                <VideoContent video={video} />
            </div>
        );
    }
}

export default Video;
