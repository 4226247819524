import gql from 'graphql-tag';
import { ItemsFragment } from './fragments';

export const GET_FEATURED_VIDEOS = gql`
    query GetFeaturedVideos($limit: Int!) {
        videoList: videos(limit: $limit, featured: true) {
            ...baseVideoFields
        }
    }
    ${ItemsFragment}
`;
