import React from 'react';
import { Helmet } from 'react-helmet-async';
import { RouteComponentProps } from 'react-router-dom';
import AdSlot from 'src/components/Ads/AdSlot/AdSlot';

import VideoListContainer from 'src/components/VideoListContainer';
import {
    GET_FEATURED_VIDEOS,
    GET_LATEST_VIDEOS,
    GET_POPULAR_VIDEOS,
} from 'src/gql';
import urlProvider from 'src/helpers/urlProvider';
import styles from './Front.module.scss';

interface IFrontRouteProps extends RouteComponentProps<{}> {}

export default function FrontRoute(props: IFrontRouteProps) {
    const { location } = props;

    return (
        <>
            <Helmet
                meta={[
                    {
                        property: 'og:url',
                        content: urlProvider(location.pathname),
                    },
                    {
                        property: 'og:type',
                        content: 'frontpage',
                    },
                ]}
            />
            <VideoListContainer
                queryData={{
                    query: GET_FEATURED_VIDEOS,
                    variables: {
                        limit: 2,
                    },
                }}
                videoListProps={{
                    title: 'Anbefalte videoer',
                    listItemVariant: 'verticalWide',
                    listType: 'horizontal',
                    hideShowMore: true,
                    showLabelBeforeData: false,
                }}
            />
            <div className={styles.container}>
                <section className={styles.list}>
                    <VideoListContainer
                        queryData={{
                            query: GET_LATEST_VIDEOS,
                            variables: {
                                limit: 12,
                            },
                        }}
                        videoListProps={{
                            title: 'Siste videoer',
                            listItemVariant: 'vertical_variant_a',
                            listType: 'horizontal',
                            tracking: {
                                action: 'siste_feed',
                                category: 'abctv_recommended_videos',
                                ga4Tracking: {
                                    event_name: 'abctv_recommended_videos',
                                    event_details_1: 'siste_feed',
                                    event_details_2: 'video_box',
                                },
                            },
                        }}
                    />
                </section>
            </div>
            <AdSlot id="topbanner-mob" className="topbannerMob" />
            <div className={styles.container}>
                <section className={styles.list}>
                    <VideoListContainer
                        queryData={{
                            query: GET_POPULAR_VIDEOS,
                            variables: {
                                limit: 12,
                            },
                        }}
                        videoListProps={{
                            title: 'Toppvalg',
                            listItemVariant: 'vertical_variant_a',
                            listType: 'horizontal',
                            tracking: {
                                action: 'popular_feed',
                                category: 'abctv_recommended_videos',
                                ga4Tracking: {
                                    event_name: 'abctv_recommended_videos',
                                    event_details_1: 'popular_feed',
                                    event_details_2: 'video_box',
                                },
                            },
                        }}
                    />
                </section>
            </div>
        </>
    );
}
