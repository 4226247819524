import gql from 'graphql-tag';

export const GET_STROSSLE_RECOMMENDATIONS = gql`
    query getStrossleRecommendations {
        strossleRecommendations(
            url: $url
            page: $page
            device: $device
            format: $format
            clientId: $clientId
        )
            @rest(
                type: "StrossleRecommendationsResponse"
                path: "/?url={args.url}&page={args.page}&device={args.device}&format={args.format}&client-id={args.clientId}"
            ) {
            uid
            cid
            t_load
            t_imp
            next_page
            provided_by @type(name: "StrossleProvidedBy") {
                link
                label
            }
            posts @type(name: "StrosslePosts") {
                id
                title
                content
                type
                url
                view
                image @type(name: "StrosslePostsImage") {
                    url
                }
                t_click
                t_imp
            }
        }
    }
`;
