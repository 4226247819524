import 'core-js/features/promise';
import polyfillPromises from 'src/polyfills/es6';

const init = () => {
    // prettier-ignore
    import(/* webpackMode: "eager" */ 'src/client');
};

Promise.all(polyfillPromises)
    .then(init)
    .catch(error => {
        const logger = require('src/services/Logger/isomorphicLogger').default;
        logger.error('Failed fetching polyfills', error);
    });
