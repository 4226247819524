import loadScript from 'src/helpers/loadScript';
import { ITracker, ITrackPageViewParams } from 'src/types/tracking';

export interface IVirtualPageParams {
    path: string;
    sections?: string;
    title?: string;
}

interface IChartbeatSettings {
    uid: number;
    domain: string;
    useCanonical: boolean;
    flickerControl: boolean;
    sections?: string;
    title?: string;
}

declare global {
    interface Window {
        _sf_async_config: IChartbeatSettings;
        pSUPERFLY: {
            virtualPage(params: IVirtualPageParams): void;
        };
    }
}

export interface IChartbeatConfig {
    id: number;
    domain: string;
}

export default class Chartbeat implements ITracker {
    private config: IChartbeatConfig;

    constructor(config: IChartbeatConfig) {
        this.config = config;
        this.registerGlobals();
    }

    public trackPageView(params: ITrackPageViewParams) {
        if (!window.pSUPERFLY) {
            window._sf_async_config = {
                ...window._sf_async_config,
                ...this.getVirtualPageParams(params),
            };
            this.init();
        } else {
            window.pSUPERFLY.virtualPage({
                ...this.getVirtualPageParams(params),
            });
        }
    }

    public trackEvent() {
        // trackEvent - is not used in chartbeat tracking
        // this functions method is just empty implementation to omit errors
    }

    private registerGlobals() {
        const { config } = this;

        window._sf_async_config = {
            uid: config.id,
            domain: config.domain,
            flickerControl: false,
            useCanonical: true,
        };
    }

    private getVirtualPageParams({ location, title }: ITrackPageViewParams) {
        const { pathname: path } = location;

        return {
            sections: 'abctv',
            ...(title && { title }),
            path,
        };
    }

    private async init() {
        await loadScript({
            url: 'https://static.chartbeat.com/js/chartbeat_video.js',
            id: 'chartbeat-script',
            attributes: [
                {
                    name: 'async',
                    value: 'true',
                },
            ],
        });
    }
}
