import parse, {
    DomElement,
    domToReact,
    HTMLReactParserOptions,
} from 'html-react-parser';
import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { Link } from 'src/components/Link';
import share from 'src/components/VideoContent/share.svg';
import time from 'src/helpers/time';
import tracking from 'src/services/Tracking';
import { IVideo } from 'src/types/video';
import styles from './VideoContent.module.scss';

export interface IVideoContentProps
    extends RouteComponentProps<{
        categoryName: string;
        playListId: string;
    }> {
    video: IVideo;
}

type TReplaceFn = false | void | object | JSX.Element | null | undefined;

const VideoContent: FC<IVideoContentProps> = props => {
    const { video } = props;
    const { title, summary, additionalHtmlSummary, published, author } = video;

    const contentRef = useRef<HTMLDivElement>(null);
    const [isClamped, setClamped] = useState(false);
    const [isExpanded, setExpanded] = useState(false);

    // Function that should be called on window resize
    const handleResize = () => {
        if (contentRef && contentRef.current) {
            setClamped(
                contentRef.current.scrollHeight >
                    contentRef.current.clientHeight
            );
        }
    };

    useEffect(() => {
        if (!window.navigator.share) {
            import('share-api-polyfill');
        }
        handleResize();
        if (
            contentRef.current &&
            contentRef.current.scrollHeight &&
            contentRef.current.clientHeight
        ) {
            setClamped(
                contentRef.current.scrollHeight >
                    contentRef.current.clientHeight
            );
        }
        // Add event listener to window resize
        window.addEventListener('resize', handleResize);

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, [video]);

    const hasLinks = (str: string): boolean =>
        !!str && str.indexOf('href=') !== -1;
    const hasLinksInSummary = hasLinks(summary);

    const toggleContent = async () => {
        await setExpanded(!isExpanded);
        tracking.trackEvent({
            category: 'abctv_video',
            action: 'description',
            label: 'see_more',
            ga4Tracking: {
                event_name: 'abctv_video',
                event_details_1: 'description',
                event_details_2: 'see_more',
                link_title: !isExpanded ? 'see_more' : 'see_less',
            },
        });
        handleResize();
    };

    const parseDescription = (descriptionSummary: string): ReactNode => {
        const options: HTMLReactParserOptions = {
            replace: ({ attribs, children }: DomElement): TReplaceFn => {
                if (!attribs || !attribs.href || !children) {
                    return;
                }
                return (
                    <Link
                        to={attribs.href}
                        isExternal={true}
                        className={styles.descriptionLink}
                        tracking={{
                            category: 'recirculation',
                            action: 'expanded-link',
                            label: attribs.href,
                        }}
                    >
                        {domToReact(children, options)}
                    </Link>
                );
            },
        };
        return parse(descriptionSummary, options);
    };

    const handleShare = () => {
        if (window.navigator.share) {
            window.navigator
                .share({
                    title: document.title || 'Del artikkelen',
                    fbId: '1861431737496296',
                    url: window.location.origin + window.location.pathname,
                })
                .catch((e: any) => {
                    // tslint:disable-next-line: no-console
                    console.warn('Error in sharing:', e);
                });
            tracking.trackEvent({
                category: 'abctv_video',
                action: 'share_video',
                ga4Tracking: {
                    event_name: 'abctv_video',
                    event_details_1: 'share_video',
                },
            });
        }
    };

    return (
        <div>
            <div className={styles.wrapper}>
                <div className={styles.contentWrapper}>
                    <h1 className={styles.title}>{title}</h1>
                    <div className={styles.timeWrapper}>
                        <time className={styles.published}>
                            Publisert: {time.formatDate(published)}
                        </time>
                        <button
                            className={styles.shareBtn}
                            onClick={handleShare}
                        >
                            <img src={share} />
                            Del
                        </button>
                    </div>
                </div>
            </div>
            <div className={styles.summary}>
                {author && <div className={styles.author}>{author}</div>}
                <div
                    className={`${styles.summaryContent} ${
                        isExpanded ? styles.expanded : ''
                    }`}
                    ref={contentRef}
                >
                    {hasLinksInSummary ? parseDescription(summary) : summary}
                </div>
                {additionalHtmlSummary && (
                    <div className={styles.additionalHtmlSummaryContent}>
                        {parseDescription(additionalHtmlSummary)}
                    </div>
                )}
                {isClamped && (
                    <button
                        className={styles.seeMoreBtn}
                        onClick={toggleContent}
                    >
                        {'Les mer'}
                    </button>
                )}
                {isExpanded && (
                    <button
                        className={styles.seeMoreBtn}
                        onClick={toggleContent}
                    >
                        {'Skjul'}
                    </button>
                )}
            </div>
        </div>
    );
};

export default withRouter(VideoContent);
