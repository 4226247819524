import React, { FC, useState } from 'react';

import Accordion from './Accordion';
import { INavigationCategory } from './Footer';

interface IMobileNavigationProps {
    navigationCategories: INavigationCategory[];
}

const MobileNavigation: FC<IMobileNavigationProps> = props => {
    const [activeItem, setActiveItem] = useState<number>(-1);

    const toggleActiveItem = (index: number, isOpen: boolean) => {
        setActiveItem(isOpen ? -1 : index);
    };

    return (
        <div>
            <Accordion
                navigationCategories={props.navigationCategories}
                activeItem={activeItem}
                onClick={toggleActiveItem}
            />
        </div>
    );
};

export default MobileNavigation;
