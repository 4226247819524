import { IHttpConfig } from '@startsiden/express-middleware-logger';

import { INavigationCategory } from 'src/components/Footer/Footer';
import { IViprPlayerPianoTracking } from 'src/components/ViprPlayerLazy/ViprPlayerLazy';
import { envVars } from 'src/config/env-vars';
import { IChartbeatConfig } from 'src/services/Tracking/Chartbeat';
import { IParselyConfig } from 'src/services/Tracking/Parsely';
import {
    IGoogleAnalyticsConfig,
    IGoogleTagManagerConfig,
    IKilkayaConfig,
} from 'src/types/tracking';
import { development } from './development';
import { localhost } from './localhost';
import { production } from './production';
import { qa } from './qa';

type TEnv = 'development' | 'qa' | 'production';
type TLogLevel = 'info' | 'silly' | 'verbose' | 'warn' | 'error' | 'debug';
type TLogOutput = 'console';

interface ILogger extends IHttpConfig {
    level: TLogLevel;
    output: TLogOutput[];
    endPoint: string;
}

export type TFeatures = 'displayAd' | string;
export type IFeatureToggle = { [key in TFeatures]: boolean };
type TAdloaderKeyValues = { [key in string]: string | string[] };

export interface IConfig {
    adloader: {
        test: boolean;
        prebid: IPrebidConfig;
        keyValues?: TAdloaderKeyValues;
    };
    adUnit: string;
    api: string;
    apiRest: string;
    adminApi: string;
    facebookAppId: number;
    host: string;
    basePath: string;
    logger: ILogger;
    player: {
        autoplay: boolean;
    };
    tracking: {
        googleAnalytics: IGoogleAnalyticsConfig;
        piano: IViprPlayerPianoTracking;
        gtm: IGoogleTagManagerConfig;
        chartbeat: IChartbeatConfig;
        parsely: IParselyConfig;
        kilkaya: IKilkayaConfig;
    };
    servicePaths: {
        startsidenPath: string;
        abcNyheterPath: string;
        searchPath: string;
        tvGuidenPath: string;
        abcGuiderPath: string;
        abcTv: string;
        piano: string;
    };
    strossle: {
        url: string;
        id: {
            videoPageRelevant: string;
        };
    };
    featureToggle: IFeatureToggle;
    navigationCategories: INavigationCategory[];
}

export interface IPrebidConfig {
    display: IPrebidDisplayConfig;
    video: {
        disable: boolean;
        debug: boolean;
        bids: [
            {
                bidder: 'appnexus';
                params: {
                    placementId: number;
                };
            }
        ];
    };
}

export interface IPrebidDisplayConfig {
    disable: boolean;
    debug: boolean;
}

const configs = {
    localhost,
    development,
    qa,
    production,
};

export default function getConfig(): IConfig {
    if (typeof window !== 'undefined' && (window as any).__CONFIG__) {
        return (window as any).__CONFIG__;
    }

    const env: TEnv = (process.env.REACT_APP_ENV as TEnv) || 'production';
    return {
        ...(configs as any)[env],
        ...envVars,
    };
}
