import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet-async';

import {
    DEFAULT_META_SITE_NAME,
    META_DESCRIPTION,
    META_SITE_NAME,
} from 'src/constants';
import urlProvider from 'src/helpers/urlProvider';

import getConfig from 'src/config';

export const Metadata: FunctionComponent = () => (
    <Helmet
        titleTemplate={`%s | ${META_SITE_NAME}`}
        defaultTitle={DEFAULT_META_SITE_NAME}
        meta={[
            {
                name: 'theme-color',
                content: '#1e8bff',
            },
            {
                name: 'viewport',
                content:
                    'width=device-width, initial-scale=1, shrink-to-fit=no',
            },
            {
                name: 'description',
                content: META_DESCRIPTION,
            },
            {
                property: 'fb:pages',
                content:
                    '143626332355969, 1704161956530281, 109156550978, 295377878302, 1311898325535839',
            },
            {
                property: 'fb:app_id',
                content: '813445132375571',
            },
            {
                property: 'og:site_name',
                content: META_SITE_NAME,
            },
            {
                property: 'og:description',
                content: META_DESCRIPTION,
            },
            {
                property: 'og:title',
                content: META_SITE_NAME,
            },
            {
                property: 'og:image',
                content: `${
                    getConfig().host
                }/abctv/assets/static/abctv-logo.jpg?v=abcn2`,
            },
            {
                property: 'og:image:width',
                content: '1200',
            },
            {
                property: 'og:image:height',
                content: '628',
            },
            {
                property: 'og:email',
                content: 'kontakt@startsiden.no',
            },
            {
                property: 'og:phone_number',
                content: '+47 22 80 85 80',
            },
            {
                property: 'og:street-address',
                content: 'Kirkegata 17',
            },
            {
                property: 'og:locality',
                content: 'Oslo',
            },
            {
                property: 'og:region',
                content: 'Oslo',
            },
            {
                property: 'og:postal-code',
                content: '0153',
            },
            {
                property: 'og:country-name',
                content: 'Norge',
            },
            {
                property: 'og:latitude',
                content: '59.91074',
            },
            {
                property: 'og:longitude',
                content: '10.74397',
            },
            {
                property: 'og:locale',
                content: 'nb_NO',
            },
            {
                property: 'og:url',
                content: urlProvider(''),
            },
            {
                name: 'twitter:card',
                content: 'summary',
            },
            {
                name: 'twitter:site',
                content: '@startsiden',
            },
            {
                name: 'twitter:creator',
                content: '@startsiden',
            },
            {
                name: 'cXenseParse:pageclass',
                content: 'frontpage',
            },
        ]}
    />
);

export default Metadata;
