import {
    IAdConfig,
    IAdLoaderSlotConfig,
    TViewportsMapVariant,
} from 'src/types/ads';

type TViewportsMap = { [key in TViewportsMapVariant]: 'tab' | 'mob' | null };

export interface IAdSlotProviderParams {
    id?: string;
    type: string;
    viewport: string;
    scale?: boolean;
    roadblocked: any;
    prebid?: any;
    useDefaultPositionFeature?: boolean;
}

const AdSlotProvider = ({
    id,
    type,
    viewport,
    scale = false,
    roadblocked,
    prebid,
    useDefaultPositionFeature,
}: IAdConfig): IAdLoaderSlotConfig => {
    const viewportsMap: TViewportsMap = {
        desktop: null,
        tablet: 'tab',
        mobile: 'mob',
    };

    const getSuffix = (separator: string, selectedViewport: string) => {
        const suffix = viewportsMap[selectedViewport as TViewportsMapVariant];
        return typeof suffix === 'string' ? `${separator}${suffix}` : '';
    };

    const getSeparatedId = (separator: string, identifier: number | string) => {
        return identifier !== '' ? `${separator}${identifier}` : '';
    };

    const getId = (
        adType: string,
        selectedViewport: string,
        identifier: string
    ) => {
        const idWithPrefix = getSeparatedId('-', identifier);
        const suffix = getSuffix('-', selectedViewport);
        return `ad-${adType}${idWithPrefix}${suffix}`;
    };

    const getType = (adType: string, selectedViewport: string) => {
        const suffix = getSuffix('_', selectedViewport);
        return `${adType}${suffix}`;
    };

    const getTargeting = (
        adType: string,
        selectedViewport: string,
        identifier: string
    ) => {
        const idWithPrefix = getSeparatedId('_', identifier);
        const suffix = getSuffix('_', selectedViewport);
        return `${adType}${idWithPrefix}${suffix}`;
    };

    return {
        id: getId(
            type || '',
            viewport,
            typeof id !== 'undefined' ? id.toString(10) : ''
        ),
        type: getType(type || '', viewport),
        only: [viewport as TViewportsMapVariant],
        scale,
        targeting: {
            2: getTargeting(
                type || '',
                viewport,
                typeof id !== 'undefined' ? id.toString() : ''
            ),
        },
        ...(roadblocked && { roadblocked }),
        ...(prebid && { prebid }),
        ...(typeof useDefaultPositionFeature !== 'undefined' && {
            useDefaultPositionFeature,
        }),
    };
};

export default AdSlotProvider;
