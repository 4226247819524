import gql from 'graphql-tag';

export const GET_AB_TEST_CONFIG = gql`
    query getAbTestConfig {
        abTestConfig @rest(type: "AbTestConfigResponse", path: "") {
            enabled: abtest_enabled
            name: abtest_name
            variations: abtest_variations
        }
    }
`;

export const GET_SOURCEPOINT_CONFIG = gql`
    query getSourcePointConfig {
        sourcePointConfig @rest(type: "SourcePointConfigResponse", path: "") {
            sourcePointEnabled: sourcepoint_enabled
        }
    }
`;
