export interface IIntersectionObserverProvider {
    target: Element;
    callback: () => void;
    options: IntersectionObserverInit;
}

export default class IntersectionObserverProvider {
    public observers = new Map();

    public observe = ({
        target,
        callback,
        options,
    }: IIntersectionObserverProvider) => {
        const instance = new IntersectionObserver(
            this.onIntersection(callback),
            options
        );
        instance.observe(target);
        this.observers.set(target, instance);
    };

    public unobserve = (target: Element | undefined) => {
        const instance = this.observers.get(target);
        if (instance) {
            instance.unobserve(target);
            this.observers.delete(target);
        }
    };

    public onIntersection = (callback: () => void) => (
        entries: IntersectionObserverEntry[]
    ) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                callback();
            }
        });
    };
}
