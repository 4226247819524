import { ApolloError } from 'apollo-client';
import React, { Component, ReactNode } from 'react';
import Button from 'src/components/Button';
import plus from 'src/components/SeeMore/plus.svg';
import { ITrackEventParams } from 'src/types/tracking';
import { IVideoBase } from 'src/types/video';
import styles from './SeeMore.module.scss';

interface ISeeMoreProps {
    handleClick: () => void;
    tracking?: ITrackEventParams;
}

export interface IRenderSeeMoreProps {
    loading: boolean;
    data?: IVideoBase[];
    total?: number;
    getMore?: () => void;
    error?: ApolloError;
    tracking?: ITrackEventParams;
}

export const renderSeeMore = ({
    loading,
    data,
    total = 0,
    getMore,
    error,
    tracking,
}: IRenderSeeMoreProps) => {
    return (
        getMore &&
        (data && total > data.length && !loading && !error && (
            <SeeMore handleClick={getMore} tracking={tracking} />
        ))
    );
};

class SeeMore extends Component<ISeeMoreProps, any> {
    public render(): ReactNode {
        const { tracking } = this.props;
        return (
            <div className={styles.seeMore}>
                <div className={styles.divider} />
                <Button
                    className={styles.seeMoreTitle}
                    onClick={this.handleClick}
                    tracking={{
                        ...(tracking || {
                            action: 'see_more',
                            category: 'see_more',
                        }),
                        label: 'see_more',
                        ga4Tracking: {
                            ...((tracking && tracking.ga4Tracking) || {
                                event_name: 'see_more',
                            }),
                            event_details_2: 'see_more',
                            link_title: 'see_more',
                        },
                    }}
                >
                    <img className={styles.plusIcon} src={plus} />
                    Se flere
                </Button>
                <div className={styles.divider} />
            </div>
        );
    }

    private handleClick = () => {
        const { handleClick } = this.props;
        if (handleClick) {
            handleClick();
        }
    };
}

export default SeeMore;
