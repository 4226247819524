import { IConfig } from './index';

export const production: IConfig = {
    adloader: {
        test: false,
        prebid: {
            display: {
                disable: false,
                debug: true,
            },
            video: {
                disable: false,
                debug: true,
                bids: [
                    {
                        bidder: 'appnexus',
                        params: {
                            placementId: 17741947,
                        },
                    },
                ],
            },
        },
    },
    adUnit: '/36021320/abcnyheter.no',
    api: 'https://video-ws.startsiden.no/v2/api',
    apiRest: 'https://video-ws.startsiden.no/rest',
    adminApi:
        'https://admin-tjenester.startsiden.no/wp-json/wp/v2/pages?slug=panel-abctv-prod',
    facebookAppId: 813445132375571,
    host: 'https://www.abcnyheter.no',
    basePath: '/abctv',
    logger: {
        appName: 'abc/TV',
        appVersion: '0.0.1',
        level: 'error',
        output: ['console'],
        endPoint: 'https://frontend-log-dev.abct.cloud/log',
    },
    player: {
        autoplay: true,
    },
    tracking: {
        googleAnalytics: {
            ga: {
                id: 'UA-27825163-5',
            },
            ga4: {
                id: 'G-ZMX2310GD4',
            },
        },
        piano: {
            siteId: '5858457539199221402',
            persistedQueryId: '0e80d5eb941ef077297743a7c39c3322f5737223',
            section: 'abctv',
        },
        gtm: {
            id: 'GTM-PFQFDS2',
        },
        chartbeat: {
            id: 19674,
            domain: 'abcnyheter.no',
        },
        parsely: {
            siteUrl: 'abctv.abcnyheter.no',
        },
        kilkaya: {
            customerId: '60ba0d8b5e8f2224b83d43c2',
        },
    },
    servicePaths: {
        startsidenPath: 'https://www.startsiden.no/',
        abcNyheterPath: 'https://www.abcnyheter.no/',
        searchPath: 'https://www.startsiden.no/sok/',
        tvGuidenPath: 'https://www.startsiden.no/tvguide/',
        abcGuiderPath: 'https://www.startsiden.no/guider',
        abcTv: 'https://www.abcnyheter.no/abctv/',
        piano:
            '//experience-eu.piano.io/xbuilder/experience/load?aid=9BVUKHQEpe',
    },
    strossle: {
        url: 'https://widgets.spklw.com/v1/data',
        id: {
            videoPageRelevant: 'aa3a0b86-87df-4083-b1d8-5c6ca2449dc7',
        },
    },
    featureToggle: {
        displayAd: true,
    },
    navigationCategories: [
        {
            text: 'Nyheter',
            urlname: 'nyheter',
            subcategories: ['norge', 'verden', 'politikk', 'sport', 'kultur'],
        },
        {
            text: 'Motor',
            urlname: 'motor',
            subcategories: ['bil', 'båt', 'MC'],
        },
        {
            text: 'Reise',
            urlname: 'reise',
            subcategories: ['inspirasjon', 'reisenyheter'],
        },
        {
            text: 'Mer',
            urlname: 'mer',
            subcategories: ['penger', 'helse', 'stemmer'],
        },
        {
            text: 'Helse',
            urlname: 'helse',
            subcategories: [
                'helse',
                'trening-og-kosthold',
                'livet',
                'teknologi',
                'tester',
            ],
        },
        {
            text: 'Penger',
            urlname: 'penger',
            subcategories: [
                'privatøkonomi',
                'forbruker',
                'makroøkonomi',
                'børs og finans',
                'næringsliv',
            ],
        },
        {
            text: 'Stemmer',
            urlname: 'stemmer',
            subcategories: [],
        },
    ],
};
