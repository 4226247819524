import { ComponentType } from 'react';

import getConfig from 'src/config';
import Category from 'src/routes/Category';
import Front from 'src/routes/Front';
import PlayList from 'src/routes/PlayList';
import Search from 'src/routes/Search';
import Video from 'src/routes/Video';

export interface IRoute {
    path: string;
    name: string;
    exact?: boolean;
    component: ComponentType<any>;
    shouldTrackPageView: boolean;
}

export type IRoutesNameVariant =
    | 'front'
    | 'sofaKings'
    | 'category'
    | 'search'
    | 'video'
    | 'playList'
    | string;

type IRoutes = { [index in IRoutesNameVariant]: IRoute };

const getPath = (path: string) => `${getConfig().basePath}${path}`;

const routes: IRoutes = {
    sofaKings: {
        path: getPath('/sofaKings'),
        name: 'sofaKings',
        exact: true,
        component: Front,
        shouldTrackPageView: true,
    },
    siste: {
        path: getPath('/siste'),
        name: 'front',
        exact: true,
        component: Front,
        shouldTrackPageView: true,
    },
    front: {
        path: getPath('/'),
        name: 'front',
        exact: true,
        component: Category,
        shouldTrackPageView: true,
    },
    category: {
        path: getPath('/:categoryName'),
        name: 'category',
        exact: true,
        component: Category,
        shouldTrackPageView: true,
    },
    search: {
        path: getPath('/search/:query'),
        name: 'search',
        exact: true,
        component: Search,
        shouldTrackPageView: true,
    },
    playList: {
        path: getPath('/play/:playListId/:videoId?/:urlSafeTitle?'),
        name: 'playList',
        exact: true,
        component: PlayList,
        shouldTrackPageView: false,
    },
    video: {
        path: getPath('/:categoryName/:videoId/:urlSafeTitle?'),
        name: 'video',
        exact: true,
        component: Video,
        shouldTrackPageView: false,
    },
};

export const routeNames = {
    SOFAKINGS: 'sofaKings',
    FRONT: 'front',
    CATEGORY: 'category',
    SEARCH: 'search',
    PLAYLIST: 'playList',
    VIDEO: 'video',
};

export default routes;
