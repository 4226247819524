import gql from 'graphql-tag';

export const GET_VARIANT = gql`
    query getAbVariantQuery {
        abVariant @client
    }
`;

export const GET_ABNAME = gql`
    query getAbName {
        abName @client
    }
`;

export const HAS_ERROR = gql`
    query hasErrorQuery {
        hasError @client
    }
`;

export const GET_URL = gql`
    query getUrlQuery {
        urlRef @client
    }
`;

export const GET_IS_FIRST_LOAD = gql`
    query getIsFirstLoadQuery {
        isFirstLoad @client
    }
`;

export const GET_LAST_TRACK_PAGE_VIEW_URL = gql`
    query getIsFirstLoadQuery {
        lastTrackPageViewUrl @client
    }
`;
