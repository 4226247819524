import React from 'react';
import { Helmet } from 'react-helmet-async';
import { RouteComponentProps } from 'react-router-dom';

import Loader from 'src/components/Loader';
import { renderSeeMore } from 'src/components/SeeMore';
import VideoList from 'src/components/VideoList';
import { GET_SEARCH_RESULTS } from 'src/gql';
import urlProvider from 'src/helpers/urlProvider';
import { useVideoListQuery } from 'src/hooks';
import styles from './Search.module.scss';

interface ISearchRouteProps
    extends RouteComponentProps<{
        query: string;
    }> {}

const SearchRoute = (props: ISearchRouteProps) => {
    const { query } = props.match.params;
    const decodedQuery = decodeURIComponent(query);
    const { location } = props;
    const { loading, data, total, getMore, error } = useVideoListQuery({
        query: GET_SEARCH_RESULTS,
        variables: {
            limit: 12,
            search: decodedQuery,
        },
    });

    return (
        <>
            <Helmet
                title={`Søkeresultat for: ${decodedQuery}`}
                meta={[
                    {
                        property: 'og:title',
                        content: `Søkeresultat for: ${decodedQuery}`,
                    },
                    {
                        property: 'og:url',
                        content: urlProvider(location.pathname),
                    },
                ]}
            />
            <main>
                <div className={styles.container}>
                    <section className={styles.list}>
                        <Loader
                            containerClass="vertical_variant_a"
                            listClass="horizontal"
                            loaderCount={12}
                            loading={loading}
                            error={error}
                        >
                            <header className={styles.header}>
                                <h4 className={styles.headerTitle}>
                                    Søkeresultat for:{' '}
                                    <strong>{decodeURIComponent(query)}</strong>
                                </h4>
                                <div className={styles.headerTotal}>
                                    Treff: {total ? total : 'n/a'}
                                </div>
                            </header>
                            <VideoList
                                listItemVariant="vertical_variant_a"
                                videoList={data}
                                listType="horizontal"
                                tracking={{
                                    category: 'recirculation',
                                    action: 'search',
                                }}
                            />
                            {total === 0 && (
                                <h4 className={styles.noResults}>
                                    Vi fant dessverre ingen treff på dette søket
                                </h4>
                            )}
                        </Loader>
                        {renderSeeMore({
                            loading,
                            data,
                            total,
                            getMore,
                            error,
                        })}
                    </section>
                </div>
            </main>
        </>
    );
};

export default SearchRoute;
