import React, { FC, memo } from 'react';
import concatClassNames from 'src/helpers/concatClassNames';
import styles from './AdSlot.module.scss';

interface IAdSlotComponent {
    id: string;
    path?: string;
    className?: string;
}

const AdSlotComponent: FC<IAdSlotComponent> = ({ id, className, path }) => {
    return (
        <div
            id={`ad-${id}`}
            className={concatClassNames(styles.adSlot, className)}
            style={{ display: 'block' }}
            data-testid="AdSlotTestId"
        />
    );
};

export default memo(AdSlotComponent);
