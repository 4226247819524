import { ApolloError } from 'apollo-client';
import React, { FC } from 'react';
import { Error } from 'src/components/Error';
import listStyles from 'src/components/VideoList/VideoList.module.scss';
import itemStyles from 'src/components/VideoListItem/VideoListItem.module.scss';
import concatClassNames from 'src/helpers/concatClassNames';
import { TVideoListItemVariant, TVideoListVariant } from 'src/types/video';
import styles from './Loader.module.scss';

export interface ILoaderProps {
    containerClass: TVideoListItemVariant;
    listClass: TVideoListVariant;
    loaderCount: number;
    loading: boolean;
    error?: ApolloError;
    replaceChildrenWhenLoading?: boolean;
    withWrapper?: boolean;
}

const Loader: FC<ILoaderProps> = ({
    containerClass,
    loaderCount,
    children,
    listClass = '',
    loading,
    error,
    replaceChildrenWhenLoading,
    withWrapper = true,
}) => {
    const placeholderCount = !error ? loaderCount : 1;
    const wrapperClassName = concatClassNames(
        styles.stretch,
        listStyles[listClass]
    );
    const containerClassName = concatClassNames(
        styles[`${containerClass}LoaderContainer`],
        styles.loadingContainer
    );

    const renderLoaderContainer = () =>
        withWrapper ? (
            <div className={wrapperClassName}>
                <div className={listStyles[`listWrapper--${containerClass}`]}>
                    <div className={listStyles.list}>{renderLoader()}</div>
                </div>
            </div>
        ) : (
            renderLoader()
        );

    const renderLoader = () =>
        Array(placeholderCount)
            .fill(placeholderCount)
            .map((value, index) => (
                <div
                    className={itemStyles[containerClass]}
                    key={index}
                    data-testid="loaderElement"
                >
                    <div className={containerClassName}>
                        <div className={styles.label}>
                            {!error ? `Laster…` : <Error />}
                        </div>
                    </div>
                </div>
            ));

    const shouldRenderLoader = loading || !!error;
    if (replaceChildrenWhenLoading) {
        return <>{shouldRenderLoader ? renderLoaderContainer() : children}</>;
    }

    return (
        <>
            {children}
            {shouldRenderLoader && renderLoaderContainer()}
        </>
    );
};

export default Loader;
