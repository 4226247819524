import { useQuery } from '@apollo/react-hooks';
import { IGraphQuery } from 'src/types/video';
import { IVideo } from 'src/types/video';

const useVideoListQuery = (queryData: IGraphQuery) => {
    const { query, variables } = queryData;
    const { loading, error, data, fetchMore } = useQuery(query, {
        variables,
        notifyOnNetworkStatusChange: true,
    });
    if (error) {
        return {
            loading,
            error,
        };
    }
    const getMore = () => {
        return fetchMore({
            ...queryData,
            variables: {
                ...queryData.variables,
                offset: data.videoList.items.length,
            },
            updateQuery: (
                prev: {
                    videoList: { items: IVideo[] };
                },
                { fetchMoreResult }
            ) => {
                if (!fetchMoreResult) {
                    return prev;
                }
                return {
                    ...prev,
                    videoList: {
                        ...fetchMoreResult.videoList,
                        items: [
                            ...prev.videoList.items,
                            ...fetchMoreResult.videoList.items,
                        ],
                    },
                };
            },
        });
    };
    const videoList = (data.videoList && data.videoList.items) || [];
    const total = data.videoList && data.videoList.total;
    return {
        loading,
        data: videoList,
        total,
        getMore,
    };
};

export default useVideoListQuery;
