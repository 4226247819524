import isBrowser from 'src/helpers/isBrowser';

async function getLogger() {
    const service = isBrowser()
        ? await import('src/services/Logger/frontendLogger')
        : await import('src/services/Logger/serverLogger');
    return service.default;
}

export default {
    error: (message: string, data?: any) =>
        getLogger().then(logger => tryLoger(logger.error, message, data)),
    silly: (message: string, data?: any) =>
        getLogger().then(logger => tryLoger(logger.silly, message, data)),
    debug: (message: string, data?: any) =>
        getLogger().then(logger => tryLoger(logger.debug, message, data)),
    verbose: (message: string, data?: any) =>
        getLogger().then(logger => tryLoger(logger.verbose, message, data)),
    info: (message: string, data?: any) =>
        getLogger().then(logger => tryLoger(logger.info, message, data)),
    warn: (message: string, data?: any) =>
        getLogger().then(logger => tryLoger(logger.warn, message, data)),
};

const tryLoger = (
    loggerFunc: (message: string, data?: any) => void,
    message: string,
    data: any
) => {
    try {
        loggerFunc(message, data);
    } catch (e) {
        return;
    }
};
