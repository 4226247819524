import isBrowser from 'src/helpers/isBrowser';

const getUrlParameter = (paramName: string) => {
    if (!isBrowser()) {
        return null;
    }
    const params = new URL(document.location.href).searchParams;

    return params.get(paramName);
};

export default getUrlParameter;
