import gql from 'graphql-tag';
import { ItemsFragment } from './fragments';

export const GET_VIDEO = gql`
    query GetVideo($id: String!) {
        video: videos(providerId: $id) {
            ...baseVideoFields
            items {
                id
                provider
                published
                author
                summary: description
                additionalHtmlSummary: htmlDescription
                tags
                site
                content_url: contentUrl
            }
        }
    }
    ${ItemsFragment}
`;
