import React, { ComponentType, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
    GET_IS_FIRST_LOAD,
    GET_LAST_TRACK_PAGE_VIEW_URL,
} from 'src/gql/queries/@clientQueries';
import useGraphqlQuery from 'src/helpers/readGraphqlQuery';
import writeGraphqlData from 'src/helpers/writeGraphqlData';
import tracking from 'src/services/Tracking';
import { ITracking } from 'src/types/tracking';

interface IWrappedComponentProps extends RouteComponentProps<any> {}

interface IWithTrackingProps extends RouteComponentProps<any> {
    trackingService: ITracking;
}

const withTracking = (WrappedComponent: ComponentType<IWithTrackingProps>) => {
    return function WithTracking(props: IWrappedComponentProps) {
        const { isFirstLoad } = useGraphqlQuery(GET_IS_FIRST_LOAD);
        const { lastTrackPageViewUrl } = useGraphqlQuery(
            GET_LAST_TRACK_PAGE_VIEW_URL
        );
        useEffect(() => {
            if (!lastTrackPageViewUrl) {
                return;
            }
            const currentPageUrlWithoutHash = window.location.href.split(
                '#'
            )[0];
            const lastTrackPageViewUrlWithoutHash = lastTrackPageViewUrl.split(
                '#'
            )[0];
            if (lastTrackPageViewUrlWithoutHash !== currentPageUrlWithoutHash) {
                setTimeout(() => {
                    tracking.trackPageView({
                        location: window.location,
                        title: document.title,
                        isFirstLoad,
                    });
                });
            }
            writeGraphqlData({ isFirstLoad: false });
            writeGraphqlData({ lastTrackPageViewUrl: window.location.href });
        }, [props.location]);

        return <WrappedComponent {...props} trackingService={tracking} />;
    };
};

export default withTracking;
