import { generatePath } from 'react-router';
import routes from 'src/routes';

type TVideoPathParamsKeys = 'videoId' | 'categoryName' | 'urlSafeTitle';
type TPlayListPathParamsKeys = 'playListId' | 'videoId' | 'urlSafeTitle';

type TSearchPathParams = { [k in 'query']: string };
type TCategoryPathParams = { [k in 'categoryName']: string };
type TVideoPathParams = { [k in TVideoPathParamsKeys]: string };
type TPlayListPathParams = { [k in TPlayListPathParamsKeys]: string };

const LATEST_SECTION_ID = 'latest';
const POPULAR_SECTION_ID = 'popular';

const getFrontPath = () => generatePath(routes.front.path);
const getFrontPathLatest = () =>
    `${generatePath(routes.front.path)}#${LATEST_SECTION_ID}`;
const getFrontPathPopular = () =>
    `${generatePath(routes.front.path)}#${POPULAR_SECTION_ID}`;
const getSistePath = () => generatePath(routes.siste.path);
const getSofaKingsPath = () => generatePath(routes.sofaKings.path);

const getVideoPath = ({
    videoId,
    categoryName,
    urlSafeTitle,
}: TVideoPathParams): string =>
    generatePath(routes.video.path, { videoId, categoryName, urlSafeTitle });

const getCategoryPath = ({ categoryName }: TCategoryPathParams): string =>
    generatePath(routes.category.path, { categoryName });

const getSearchPath = (routeParams: TSearchPathParams): string =>
    generatePath(routes.search.path, routeParams);

const getExternalSearchPath = (): string => '/sok/';

const getPlayListPath = (routeParams: TPlayListPathParams): string =>
    generatePath(routes.playList.path, routeParams);

export default {
    getFrontPath,
    getFrontPathLatest,
    getFrontPathPopular,
    getSistePath,
    getSofaKingsPath,
    getVideoPath,
    getCategoryPath,
    getSearchPath,
    getExternalSearchPath,
    getPlayListPath,
    LATEST_SECTION_ID,
    POPULAR_SECTION_ID,
};
