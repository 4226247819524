import { createContext } from 'react';
import { TAbVariant } from 'src/types/abTests';

export interface IVariantContext {
    abVariant: TAbVariant;
}

const initialContext: IVariantContext = { abVariant: '' };
const AbVariantContext = createContext(initialContext);

export const AbVariantProvider = AbVariantContext.Provider;
export const AbVariantConsumer = AbVariantContext.Consumer;
export default AbVariantContext;
