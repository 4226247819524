import React, { Component } from 'react';
import InView from 'src/components/InView';
import imageProxy from 'src/helpers/imageProxy';
import styles from './LazyLoadImage.module.scss';

export interface ILazyLoadImageProps {
    className?: string;
    url: string;
    options: IntersectionObserverInit;
    width: number;
}
export interface ILazyLoadImageState {
    isRetina: boolean;
}

class LazyLoadImage extends Component<
    ILazyLoadImageProps,
    ILazyLoadImageState
> {
    public static defaultProps = {
        options: {
            root: null,
            rootMargin: '0px 0px 300px 0px',
            threshold: 0,
        },
        width: 360,
    };

    public state = {
        isRetina: false,
    };

    public componentDidMount = () => {
        this.checkRetina();
    };

    public render() {
        const { url, width, className } = this.props;

        return (
            <div>
                <InView
                    options={this.props.options}
                    className={styles.container}
                >
                    <div
                        className={`${
                            styles.backgroundImageContainer
                        } ${className}`}
                        style={{
                            backgroundImage: this.getBackgroundImage(
                                url,
                                width
                            ),
                        }}
                    />
                </InView>
            </div>
        );
    }

    private getBackgroundImage(url: string, width: number) {
        return this.state.isRetina
            ? `url(${imageProxy(url, width * 2)})`
            : `url(${imageProxy(url, width)})`;
    }

    private checkRetina(): void {
        if (window.matchMedia) {
            const mediaQuery =
                'only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), ' +
                'only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen  and (min-device-pixel-ratio: 1.3), ' +
                'only screen and (min-resolution: 1.3dppx)';
            const mq = window.matchMedia(mediaQuery);
            this.setState({
                isRetina: (mq && mq.matches) || window.devicePixelRatio > 1,
            });
        }
    }
}

export default LazyLoadImage;
