import loadScript from 'src/helpers/loadScript';
import logger from 'src/services/Logger/isomorphicLogger';
import {
    IGA4PageViewParams,
    IGoogleTagManager,
    IGoogleTagManagerConfig,
    ITrackEventParams,
    ITrackPageViewParams,
} from 'src/types/tracking';

declare global {
    interface Window {
        dataLayer: any[];
    }
}

export default class GoogleTagManager implements IGoogleTagManager {
    public config: IGoogleTagManagerConfig;

    constructor(config: IGoogleTagManagerConfig) {
        this.config = config;
        this.registerGlobals();
        this.init();
        this.load().catch(error => {
            logger.error('GTM not loaded.', { error });
        });
    }

    public trackPageView({
        title,
        video,
        isFirstLoad,
        ga4Tracking,
    }: ITrackPageViewParams) {
        if (window && window.dataLayer && isFirstLoad === false) {
            // isFirstLoad is undefined initially
            const data: IGA4PageViewParams & { event: string } = {
                event: 'pageViewCustomEvent',
                article_title: title,
                article_tags: video ? video.tags : '',
                article_has_video: `${!!video}`,
                ...ga4Tracking,
            };
            window.dataLayer.push(data);
        }
    }

    public trackEvent(params: ITrackEventParams) {
        if (window && window.dataLayer && params.ga4Tracking) {
            const data = {
                event: 'custom_ga4_event',
                ...params.ga4Tracking,
            };
            window.dataLayer.push(data);
        }
    }

    public init = () => {
        const tags = document.head.querySelector<HTMLMetaElement>(
            '[name~=parsely-tags][content]'
        );
        window.dataLayer.push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js',
            abtest_group: this.config.abtestGroup || '',
            title: document.title,
            tags: tags ? tags.content.split(',') : undefined,
        });
    };

    private registerGlobals() {
        window.dataLayer = window.dataLayer || [];
    }

    private load = () => {
        return loadScript({
            url: `https://www.googletagmanager.com/gtm.js?id=${this.config.id}`,
            id: 'gtm-script',
            attributes: [
                {
                    name: 'async',
                    value: 'true',
                },
            ],
        });
    };
}
