import React, { Component, ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';
import { RouteComponentProps } from 'react-router-dom';

import pathGenerator from 'src/helpers/pathGenerator';
import urlProvider from 'src/helpers/urlProvider';
import CategoryProvider from 'src/services/CategoryProvider';

import AdSlot from 'src/components/Ads/AdSlot/AdSlot';
import VideoListContainer from 'src/components/VideoListContainer';
import CategoryContext from 'src/context/CategoryContext';
import { GET_CATEGORY_LATEST, GET_CATEGORY_POPULAR } from 'src/gql';
import { getOGTitle } from 'src/helpers/openGraph';
import { IVideoDisplayAd } from 'src/types/ads';
import styles from './Category.module.scss';

interface ICategoryRouteProps
    extends RouteComponentProps<{
        categoryName: string;
    }> {}

export default class CategoryRoute extends Component<ICategoryRouteProps> {
    public render(): ReactNode {
        const { location, match } = this.props;
        const frontCategoryName = 'nyheter';
        const { categoryName = frontCategoryName } = match.params;
        const isFrontpage = categoryName === frontCategoryName;
        const category = CategoryProvider.getByUrlSafeName(categoryName);
        const { description, site } = category;
        const adConfig: IVideoDisplayAd = {
            type: 'topbanner',
            place: [6],
            exclude: ['desktop', 'tablet'],
        };
        const listDisplayAd =
            categoryName === frontCategoryName ? adConfig : undefined;
        return (
            <CategoryContext.Provider value={{ name: categoryName }}>
                <Helmet
                    title={isFrontpage ? '' : categoryName}
                    meta={[
                        {
                            name: 'description',
                            content: description,
                        },
                        {
                            property: 'og:title',
                            content: isFrontpage
                                ? 'ABC Nyheter TV'
                                : getOGTitle(categoryName),
                        },
                        {
                            property: 'og:url',
                            content: urlProvider(location.pathname),
                        },
                        {
                            property: 'og:description',
                            content: description,
                        },
                        {
                            property: 'og:type',
                            content: isFrontpage ? 'frontpage' : 'website',
                        },
                    ]}
                />
                <main>
                    <div className={styles.container}>
                        <section
                            id={pathGenerator.LATEST_SECTION_ID}
                            className={`${styles.list} ${
                                listDisplayAd ? styles.noMargin : ''
                            }`}
                        >
                            <VideoListContainer
                                queryData={{
                                    query: GET_CATEGORY_LATEST,
                                    variables: {
                                        limit: 12,
                                        site,
                                    },
                                }}
                                videoListProps={{
                                    title: `Siste`,
                                    listItemVariant: 'vertical_variant_a',
                                    listType: 'horizontal',
                                    displayAd: listDisplayAd,
                                    tracking: {
                                        action: 'siste_feed',
                                        category: 'abctv_recommended_videos',
                                        ga4Tracking: {
                                            event_name:
                                                'abctv_recommended_videos',
                                            event_details_1: 'siste_feed',
                                            event_details_2: 'video_box',
                                        },
                                    },
                                }}
                            />
                        </section>
                    </div>
                    {!listDisplayAd && (
                        <AdSlot id="topbanner-mob" className="topbannerMob" />
                    )}
                    <div className={styles.container}>
                        <section
                            id={pathGenerator.POPULAR_SECTION_ID}
                            className={styles.list}
                        >
                            <VideoListContainer
                                queryData={{
                                    query: GET_CATEGORY_POPULAR,
                                    variables: {
                                        limit: 12,
                                        site,
                                    },
                                }}
                                videoListProps={{
                                    title: `Populære`,
                                    listItemVariant: 'vertical_variant_a',
                                    listType: 'horizontal',
                                    tracking: {
                                        action: 'popular_feed',
                                        category: 'abctv_recommended_videos',
                                        ga4Tracking: {
                                            event_name:
                                                'abctv_recommended_videos',
                                            event_details_1: 'popular_feed',
                                            event_details_2: 'video_box',
                                        },
                                    },
                                }}
                            />
                        </section>
                    </div>
                </main>
            </CategoryContext.Provider>
        );
    }
}
