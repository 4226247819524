import React, { ComponentType, useEffect, useRef } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import Adloader from 'src/components/Ads/Adloader';
import { ErrorBoundary } from 'src/components/Error';
import Footer from 'src/components/Footer';
import Header from 'src/components/Header';
import Metadata from 'src/components/Metadata';
import ScrollTop from 'src/components/ScrollTop';
import { AbVariantProvider } from 'src/context/AbVariantContext';
import { GET_URL, GET_VARIANT, HAS_ERROR } from 'src/gql';
import isBrowser from 'src/helpers/isBrowser';
import useGraphqlQuery from 'src/helpers/readGraphqlQuery';
import routes, { IRoute } from 'src/routes';
import ErrorPage, { ErrorWithTracking } from 'src/routes/Error';
import withTracking from 'src/routes/withTracking';
import { ILocalState } from 'src/types/types';
import writeGraphqlData from './helpers/writeGraphqlData';

function getComponent({
    route,
    shouldRenderError,
}: {
    route: IRoute;
    shouldRenderError: boolean;
}): ComponentType<RouteComponentProps<any>> | ComponentType<any> {
    const { component, shouldTrackPageView } = route;
    const componentToRender = shouldRenderError ? ErrorPage : component;
    return shouldTrackPageView
        ? withTracking(componentToRender)
        : componentToRender;
}

function App({ location }: RouteComponentProps) {
    let shouldRenderError = false;
    const headerRef = useRef<HTMLDivElement>(null);

    const response = useGraphqlQuery(HAS_ERROR);
    shouldRenderError = !!response.hasError;

    useEffect(() => {
        if (shouldRenderError) {
            writeGraphqlData({ hasError: false });
        }
    }, [location]);

    const data = useGraphqlQuery(GET_VARIANT) as ILocalState;
    const abVariant = data && data.abVariant ? data.abVariant : '';
    const { urlRef = [] } = useGraphqlQuery(GET_URL) as ILocalState;
    const currentUrl = isBrowser() ? window.location.href : '';
    useEffect(() => {
        writeGraphqlData({
            urlRef: [currentUrl, ...urlRef],
        });
    }, [currentUrl]);

    return (
        <>
            <AbVariantProvider value={{ abVariant }}>
                <ScrollTop />
                <Metadata />
                <Header location={location} />
                <div className="App pageWrapper">
                    <Adloader />
                    <ErrorBoundary>
                        <Switch>
                            {Object.keys(routes).map((routeName: string) => {
                                const route = routes[routeName];
                                const { name, path, exact } = route;
                                const component = getComponent({
                                    route,
                                    shouldRenderError,
                                });

                                return (
                                    <Route
                                        key={name}
                                        path={path}
                                        component={component}
                                        name={name}
                                        exact={exact}
                                    />
                                );
                            })}
                            <Route component={ErrorWithTracking} />
                        </Switch>
                    </ErrorBoundary>
                </div>
                <Footer scrollToRef={headerRef} />
            </AbVariantProvider>
        </>
    );
}

export default withRouter(App);
