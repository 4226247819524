import React, { FC, MouseEvent, ReactNode } from 'react';
import concatClassNames from 'src/helpers/concatClassNames';
import trackingService from 'src/services/Tracking';
import { ITrackEventParams } from 'src/types/tracking';
import styles from './Button.module.scss';

interface IButtonProps {
    children: ReactNode;
    className?: string;
    disabled?: boolean;
    onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
    tracking?: ITrackEventParams;
}

const Button: FC<IButtonProps> = ({
    children,
    className,
    disabled = false,
    onClick,
    tracking,
}) => {
    function onButtonClick(event: MouseEvent<HTMLButtonElement>) {
        if (tracking) {
            trackingService.trackEvent(tracking);
        }

        if (onClick) {
            onClick(event);
        }
    }

    return (
        <button
            type="button"
            disabled={disabled}
            onClick={onButtonClick}
            className={concatClassNames(styles.button, className)}
        >
            {children}
        </button>
    );
};

export default Button;
