import React from 'react';
import VideoListItem from 'src/components/VideoListItem';
import { ITrackEventParams } from 'src/types/tracking';
import { IVideoBase, TVideoListItemVariant } from 'src/types/video';

const getTracking = ({
    index,
    tracking,
    title,
}: {
    index: number;
    tracking?: ITrackEventParams;
    title: string;
}) => {
    const actionName = (tracking && tracking.action) || title || '';
    return {
        category: (tracking && tracking.category) || 'recirculation',
        action: actionName,
        label: `video_box_${index + 1}`,
        ...(tracking &&
            tracking.ga4Tracking && {
                ga4Tracking: {
                    ...tracking.ga4Tracking,
                    link_title: title,
                    element_order: `${index + 1}`,
                },
            }),
    };
};

const generateVideoList = ({
    videoList,
    listItemVariant,
    onClick,
    tracking,
    selectedVideoId,
    showPublished,
}: {
    videoList: IVideoBase[];
    listItemVariant: TVideoListItemVariant;
    onClick?: () => void;
    tracking?: ITrackEventParams;
    title: string;
    selectedVideoId?: string;
    showPublished?: boolean;
}) => {
    if (videoList) {
        const videoComponents = videoList.map(
            (video: IVideoBase, index: number) => {
                return (
                    <VideoListItem
                        videoItem={video}
                        variant={listItemVariant}
                        key={video.provider_id}
                        onClick={onClick}
                        tracking={getTracking({
                            index,
                            tracking,
                            title: video.title,
                        })}
                        selected={selectedVideoId === video.provider_id}
                        showPublished={showPublished}
                    />
                );
            }
        );
        return videoComponents;
    }
    return [];
};

export default generateVideoList;
