import { IAdConfig, IPrebidAdConfig, IPrebidParams } from 'src/types/ads';

const PREBID_TOPBANNER_MOB: IPrebidParams = {
    improveId: 13308301,
    ixId: '1137507',
    adformId: 659570,
    rubiconId: '1257760',
    appnexusId: 16347044,
};

const PREBID_RECTANGLE: IPrebidParams = {
    improveId: 13308309,
    ixId: '1137510',
    adformId: 659491,
    rubiconId: '1255672',
    appnexusId: 16346970,
};

const getPrebidConfig = ({
    improveId,
    ixId,
    adformId,
    rubiconId,
    appnexusId,
}: IPrebidParams): IPrebidAdConfig => ({
    bidders: {
        ...(improveId && {
            improvedigital: {
                params: {
                    placementId: improveId,
                },
            },
        }),
        ...(ixId && {
            ix: {
                params: {
                    siteId: ixId,
                },
            },
        }),
        ...(adformId && {
            adform: {
                params: {
                    mid: adformId,
                    priceType: 'net',
                },
            },
        }),
        ...(rubiconId && {
            rubicon: {
                params: {
                    accountId: '19992',
                    siteId: '253460',
                    zoneId: rubiconId,
                },
            },
        }),
        ...(appnexusId && {
            appnexus: {
                params: {
                    placementId: appnexusId,
                },
            },
        }),
    },
});

const topBannerMob: IAdConfig = {
    type: 'topbanner',
    viewport: 'mobile',
    scale: 'down',
    prebid: getPrebidConfig(PREBID_TOPBANNER_MOB),
};

const sidebarAd: IAdConfig = {
    type: 'rectangle',
    viewport: 'desktop',
    scale: 'down',
    prebid: getPrebidConfig(PREBID_RECTANGLE),
};

const sidebarAdTab: IAdConfig = {
    type: 'rectangle',
    viewport: 'tablet',
    scale: 'down',
    prebid: getPrebidConfig(PREBID_RECTANGLE),
};

const sidebarAd2: IAdConfig = {
    id: 2,
    type: 'rectangle',
    viewport: 'desktop',
    scale: 'down',
    prebid: getPrebidConfig(PREBID_RECTANGLE),
};

const sidebarAdTab2: IAdConfig = {
    id: 2,
    type: 'rectangle',
    viewport: 'tablet',
    scale: 'down',
    prebid: getPrebidConfig(PREBID_RECTANGLE),
};

export const getNetboard = ({
    id,
    viewport,
    scale,
    prebid,
}: IAdConfig): IAdConfig => ({
    ...(id && { id }),
    type: 'netboard',
    viewport,
    scale,
    ...(prebid && { prebid }),
});

export const frontSlots = [topBannerMob];

export const videoSlots = [
    topBannerMob,
    sidebarAd,
    sidebarAdTab,
    sidebarAd2,
    sidebarAdTab2,
];

export const categorySlots = [topBannerMob];
