export { GET_VIDEO } from './queries/getVideo';
export { GET_LATEST_VIDEOS } from './queries/getLatestVideos';
export { GET_POPULAR_VIDEOS } from './queries/getPopularVideos';
export { GET_RELATED_VIDEOS } from './queries/getRelatedVideos';
export { GET_CATEGORY_POPULAR } from './queries/getCategoryPopular';
export { GET_CATEGORY_LATEST } from './queries/getCategoryLatest';
export { GET_SEARCH_RESULTS } from './queries/getSearchResults';
export { GET_FEATURED_VIDEOS } from './queries/getFeaturedVideos';
export { GET_PLAYLIST_VIDEO } from './queries/getPlayListVideos';
export { GET_AB_TEST_CONFIG } from './queries/getAdminSettings';
export {
    GET_STROSSLE_RECOMMENDATIONS,
} from './queries/getStrossleRecommendations';
export {
    GET_VARIANT,
    HAS_ERROR,
    GET_URL,
    GET_ABNAME,
} from './queries/@clientQueries';

export { POST_LOGS } from './mutations/postLogs';
