import client from 'src/apollo/client';
import getConfig from 'src/config';
import { GET_ABNAME, GET_VARIANT } from 'src/gql';
import isBrowser from 'src/helpers/isBrowser';
import Chartbeat from 'src/services/Tracking/Chartbeat';
import Parsely from 'src/services/Tracking/Parsely';
import Piano from 'src/services/Tracking/Piano';
import { IGoogleTagManagerConfig, ITracker } from 'src/types/tracking';
import GoogleTagManager from './GoogleTagManager';
import Kilkaya from './Kilkaya';
import Tracking from './Tracking';

const readQuery = (query: string) => {
    return client.readQuery({
        query,
        variables: {
            fetchPolicy: 'cache-only',
        },
    });
};

const getABTestData = (): string => {
    try {
        const { abVariant } = readQuery(GET_VARIANT);
        const { abName } = readQuery(GET_ABNAME);
        return abVariant && abName ? `${abName}_${abVariant}` : '';
    } catch (error) {
        return '';
    }
};

const gtmConfig: IGoogleTagManagerConfig = {
    ...getConfig().tracking.gtm,
    abtestGroup: getABTestData(),
};

const getTrackers = () => {
    return [
        new GoogleTagManager(gtmConfig),
        new Chartbeat(getConfig().tracking.chartbeat),
        new Parsely(getConfig().tracking.parsely),
        new Kilkaya(getConfig().tracking.kilkaya),
        new Piano({ url: getConfig().servicePaths.piano }),
    ];
};

const implementations: ITracker[] = isBrowser() ? getTrackers() : [];

export default new Tracking(implementations);
