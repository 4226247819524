import { Request } from 'express';
import getUrlParameter from 'src/helpers/getUrlParameter';

const NO_ADS_KEY = 'adkv_test';
const NO_ADS_VALUE = 'noAds';

const shouldDisableAds = (req?: Request) => {
    if (typeof req !== 'undefined' && req.query[NO_ADS_KEY] === NO_ADS_VALUE) {
        return true;
    }

    return getUrlParameter(NO_ADS_KEY) === NO_ADS_VALUE;
};

export default shouldDisableAds;
