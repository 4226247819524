import getConfig from 'src/config';
import loadScript from 'src/helpers/loadScript';
import { getOGTitle } from 'src/helpers/openGraph';
import { formatIsoDate } from 'src/helpers/time/time';
import logger from 'src/services/Logger/isomorphicLogger';
import { IKilkaya, IKilkayaConfig } from 'src/types/tracking';
import { ITrackPageViewParams } from 'src/types/tracking';
import CategoryProvider from '../CategoryProvider/CategoryProvider';

interface IK5AMeta {
    title: string;
    section: string;
    description: string;
    image: string;
    tags: string;
    type: string;
    publishtime: string;
    author?: string;
    modifiedtime?: string;
}

declare global {
    interface Window {
        k5aMeta?: IK5AMeta;
    }
}

export default class Kilkaya implements IKilkaya {
    public config: IKilkayaConfig;

    constructor(config: IKilkayaConfig) {
        this.config = config;
        this.init();
        this.load().catch(error => {
            logger.error('Kilkaya not loaded.', { error });
        });
    }

    public trackPageView({ title, video, isFirstLoad }: ITrackPageViewParams) {
        if (isFirstLoad === false) {
            const mainCategory =
                video && video.site
                    ? CategoryProvider.getBySite(video.site).name
                    : '';
            const image = video
                ? video.imageUrl
                : `${
                      getConfig().host
                  }/abctv/assets/static/abctv-logo.jpg?v=abcn2`;
            const k5aMeta = {
                title: title ? getOGTitle(title) : document.title,
                author: video && video.author ? video.author : '',
                section: mainCategory,
                description: video ? video.summary : '',
                image,
                tags: video ? video.tags : '',
                type: video ? 'video' : 'frontpage',
                publishtime: `${video ? formatIsoDate(video.published) : ''}`,
            };
            window.k5aMeta = k5aMeta;
            document.dispatchEvent(new Event('K5A:SpaReady'));
        }
    }

    public trackEvent(): void {
        return;
    }

    public init = (): void => {
        return;
    };

    private load = () => {
        return loadScript({
            url: `//cl.k5a.io/${this.config.customerId}.js`,
            id: 'kilkaya-script',
            attributes: [
                {
                    name: 'async',
                    value: 'true',
                },
            ],
        });
    };
}
