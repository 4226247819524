import React, { FC } from 'react';
import { renderSeeMore } from 'src/components/SeeMore';
import VideoList from 'src/components/VideoList';
import useVideoListQuery from 'src/hooks/useVideoListQuery';
import { IGraphQuery } from 'src/types/video';
import { IVideoListContainerProps } from 'src/types/videoList';

interface IVideoListContainer {
    queryData: IGraphQuery;
    videoListProps: IVideoListContainerProps;
}

const VideoListContainer: FC<IVideoListContainer> = props => {
    const { queryData, videoListProps } = props;
    const {
        replaceChildrenWhenLoading = true,
        displayAd,
        title,
        listItemVariant,
        onClick,
        listType,
        showLabelBeforeData = true,
        showPublished,
        tracking,
    } = videoListProps;

    const {
        data: videoList,
        loading: videoListLoading,
        error,
        total,
        getMore,
    } = useVideoListQuery(queryData);
    const loading = videoListLoading;
    return (
        <>
            <VideoList
                loading={loading}
                error={error}
                replaceChildrenWhenLoading={replaceChildrenWhenLoading}
                displayAd={displayAd}
                title={title}
                listItemVariant={listItemVariant}
                videoList={videoList}
                onClick={onClick}
                listType={listType}
                loaderCount={queryData.variables.limit}
                showLabelBeforeData={showLabelBeforeData}
                showPublished={showPublished}
                query={queryData.query}
                tracking={tracking}
            />
            {!videoListProps.hideShowMore &&
                renderSeeMore({
                    loading,
                    data: videoList,
                    total,
                    getMore,
                    error,
                    tracking,
                })}
        </>
    );
};

export default VideoListContainer;
