import gql from 'graphql-tag';

export const ItemsFragment = gql`
    fragment baseVideoFields on VideoResponse {
        items {
            advertiser
            duration
            imageUrl
            title
            urlSafeTitle
            provider_id: providerId
            site
            tags
            __typename
        }
        total
        __typename
    }
`;
