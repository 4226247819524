import logger from 'src/services/Logger/isomorphicLogger';
import { IStorageAPI } from 'src/services/Storage';

export interface IStorage {
    storage: Storage | IStorageAPI;
}

export default class LocalStorage implements IStorage {
    public storage: Storage | IStorageAPI;

    constructor(storage: Storage | IStorageAPI) {
        this.storage = storage;
    }

    public get(key: string) {
        try {
            const value = this.storage.getItem(key);
            return value ? JSON.parse(value) : null;
        } catch (error) {
            logger.error('Storage.get', error);
            return null;
        }
    }

    public set(key: string, value: any) {
        try {
            this.storage.setItem(key, JSON.stringify(value));
        } catch (error) {
            logger.error('Storage.set', error);
        }
    }

    public remove(key: string) {
        try {
            this.storage.removeItem(key);
        } catch (error) {
            logger.warn('Storage.remove', error);
        }
    }

    public push(key: string, value: any) {
        const collection = this.get(key) || [];
        const isArray = Array.isArray(collection);

        if (!isArray) {
            logger.warn(
                `Value stored under the key: ${key} is not valid Array. It's not possible to push items.`
            );
        }

        if (isArray && !collection.includes(value)) {
            collection.push(value);
            this.set(key, collection);
        }

        return collection;
    }

    public pushToLimitedCollection(key: string, id: string, limit = 100) {
        this.push(key, id);
        this._clearItemsExceedLimit(key, limit);
    }

    public _clearItemsExceedLimit(key: string, limit: number) {
        const value = this.get(key);

        if (!Array.isArray(value)) {
            return;
        }

        if (value.length > limit) {
            value.splice(0, value.length - limit);
            this.set(key, value);
        }

        return value;
    }
}
