import { FALLBACK_CATEGORY_NAME } from 'src/constants';
import { IJwVideo } from 'src/types/video';
const SITE_TAG_PREFIX = 'meta:site=';

export default class VideoTagsParser {
    public static getTags(video: IJwVideo): string[] {
        const { tags } = video.item;

        if (!tags) {
            return [];
        }

        return tags.split(',');
    }

    public static getMetaSiteTags(video: IJwVideo): string[] {
        const tags = VideoTagsParser.getTags(video);
        const isMetaSiteTag = (tag: string) =>
            tag.substring(0, SITE_TAG_PREFIX.length) === SITE_TAG_PREFIX;

        return tags.filter(isMetaSiteTag);
    }

    public static getSiteName(video: IJwVideo): string {
        const metaSiteTags = VideoTagsParser.getMetaSiteTags(video);

        return metaSiteTags.length
            ? metaSiteTags[0].substring(SITE_TAG_PREFIX.length)
            : FALLBACK_CATEGORY_NAME;
    }
}
