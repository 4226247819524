/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { FC, useEffect, useState } from 'react';
import ABCNyheter from 'src/components/Footer/logos/ABCNyheter.svg';
import Facebook from 'src/components/Footer/logos/Facebook.svg';
import RedArrowRight from 'src/components/Footer/logos/RedArrowRight.svg';
import getConfig from 'src/config';
import { Link } from '../Link';
import CompanyInfo from './CompanyInfo';
import MobileNavigation from './MobileNavigation';
import Navigation from './Navigation';
import styles from './styles/Footer.module.scss';

export interface IFooterProps {
    scrollToRef: React.RefObject<HTMLElement>;
}

export interface INavigationCategory {
    text: string;
    urlname: string;
    subcategories: string[];
}

interface ILink {
    name: string;
    url: string;
    gaLabel?: string;
    target?: '_blank' | '_self';
}

declare global {
    interface Window {
        _sp_?: { gdpr?: { loadPrivacyManagerModal?: (id: number) => void } };
    }
}

const renderLogos = (link: ILink) => {
    const linkClass = styles[link.gaLabel || ''] || '';
    return (
        <li key={`renderLink-${link.url}`}>
            <Link
                to={link.url}
                tracking={{
                    category: 'footer',
                    action: 'logo',
                    label: `${link.gaLabel}`,
                }}
                data-ga4-event-name={'footer'}
                data-ga4-event-details-1={'logo'}
                data-ga4-event-details-2={link.gaLabel}
                data-ga4-link-title={link.name}
                data-ga4-link-url={link.url}
                className={linkClass}
                isExternal={true}
                target={link.target || '_blank'}
            />
        </li>
    );
};

const renderLinks = (
    urls: ILink[],
    eventDetails2: string,
    showSPBtn: boolean
) => {
    return (
        <ul>
            {urls.map((link, index) =>
                renderLink(link, index, eventDetails2, showSPBtn)
            )}
        </ul>
    );
};

const handleBtnClick = () => {
    if (
        window &&
        window._sp_ &&
        window._sp_.gdpr &&
        window._sp_.gdpr.loadPrivacyManagerModal
    ) {
        window._sp_.gdpr.loadPrivacyManagerModal(896804);
    }
};

const renderLink = (
    link: ILink,
    index: number,
    eventDetails2: string,
    showSPBtn: boolean
) => {
    if (link.name === 'Annonseinnstillinger') {
        if (!showSPBtn) {
            return null;
        }
        return (
            <li
                key={`renderLink-${index}-${link.url}`}
                className={styles.columnItem}
            >
                <button
                    onClick={handleBtnClick}
                    title={link.name}
                    className={styles.privacyButton}
                    data-ga4-event-name={'footer'}
                    data-ga4-event-details-1={'contact'}
                    data-ga4-event-details-2={eventDetails2}
                    data-ga4-link-title={link.name}
                    data-ga4-link-url={link.url}
                >
                    {link.name}
                </button>
            </li>
        );
    }
    return (
        <li
            className={styles.columnItem}
            key={`renderLink-${index}-${link.url}`}
        >
            <Link
                to={link.url}
                tracking={{
                    category: 'footer',
                    action: 'navigation',
                    label: `${link.gaLabel}`,
                }}
                data-ga4-event-name={'footer'}
                data-ga4-event-details-1={'contact'}
                data-ga4-event-details-2={eventDetails2}
                data-ga4-link-title={link.name}
                data-ga4-link-url={link.url}
                isExternal={true}
            >
                {link.name}
            </Link>
        </li>
    );
};

const mainLinks: ILink[] = [
    {
        gaLabel: 'startsiden',
        name: 'ABC Startsiden',
        url: getConfig().servicePaths.startsidenPath,
    },
    {
        gaLabel: 'abcnyheter',
        name: 'ABC Nyheter',
        url: getConfig().servicePaths.abcNyheterPath,
        target: '_self',
    },
    {
        gaLabel: 'abcguider',
        name: 'ABC Guider',
        url: getConfig().servicePaths.abcGuiderPath,
    },
    {
        gaLabel: 'sok',
        name: 'ABC Søk',
        url: getConfig().servicePaths.searchPath,
    },
    {
        gaLabel: 'tvguiden',
        name: 'ABC TVGuiden',
        url: getConfig().servicePaths.tvGuidenPath,
    },
    {
        gaLabel: 'abctv',
        name: 'ABC TV',
        url: getConfig().servicePaths.abcTv,
    },
];

const urlsInfo: ILink[] = [
    {
        gaLabel: 'personvern',
        name: 'Personvernpolicy',
        url: 'https://om.startsiden.no/retningslinjer-for-personvern/',
    },
    {
        gaLabel: 'kontakt_oss',
        name: 'Kontakt oss',
        url: 'https://om.startsiden.no/kontakt/',
    },
    {
        gaLabel: 'om_oss',
        name: 'Om oss',
        url: 'https://om.startsiden.no/',
    },
    {
        gaLabel: 'nettstedskart',
        name: 'Nettstedskart',
        url: 'https://www.abcnyheter.no/nettstedskart',
    },
    {
        gaLabel: 'annonseinnstillinger',
        name: 'Annonseinnstillinger',
        url: '',
    },
];
const urlsSalg: ILink[] = [
    {
        gaLabel: 'annonseweb',
        name: 'Annonseweb',
        url: 'https://om.startsiden.no/2018-annonseinfo/',
    },
    {
        gaLabel: 'våre_produkter',
        name: 'Våre produkter',
        url: 'https://om.startsiden.no/startsiden/',
    },
    {
        gaLabel: 'contact_us',
        name: 'Kontakt',
        url: 'https://om.startsiden.no/kontakt/',
    },
];

const Footer: FC<IFooterProps> = props => {
    const { scrollToRef } = props;
    const [showSPBtn, setShowSPBtn] = useState<boolean>(false);
    const urlCompany: string = 'https://om.startsiden.no';
    const navigationCategories = getConfig().navigationCategories;

    useEffect(() => {
        if (window && window._sp_) {
            setShowSPBtn(true);
        }
    }, []);

    const BackToTopButton = () => {
        const onScrollToBtnClick = () => {
            const scrollToElem = scrollToRef.current;
            if (document.activeElement) {
                const activeElement: any = document.activeElement;
                activeElement.blur();
            }
            if (scrollToElem) {
                scrollToElem.scrollIntoView({
                    behavior: 'smooth',
                });
            } else {
                window.scrollTo({
                    behavior: 'smooth',
                    top: 0,
                });
            }
        };
        return (
            <button
                className={styles.backToTop}
                onClick={onScrollToBtnClick}
                data-ga4-event-details-1="back_to_top"
            >
                Til toppen
                <img
                    className={styles.redArrow}
                    src={RedArrowRight}
                    alt="Red Arrow"
                />
            </button>
        );
    };
    return (
        <footer className={styles.footer} data-ga4-event-name="footer">
            <div className={styles.footerContainer}>
                <div className={`${styles.topContainer} ${styles.footerBox}`}>
                    <Link
                        to="/"
                        tracking={{
                            category: 'footer',
                            action: 'logo',
                            label: 'abcnyheter',
                        }}
                        data-ga4-event-details-1="logo"
                        data-ga4-link-title="abcnyheter"
                        data-ga4-link-url="/"
                        className={styles.logo}
                        isExternal={false}
                    >
                        <img
                            className={styles.brandImg}
                            src={ABCNyheter}
                            alt="ABC Nyheter Logo"
                        />
                    </Link>
                    {scrollToRef && <BackToTopButton />}
                </div>

                <div className={`${styles.footerBox} ${styles.infoWrapper}`}>
                    <div className={styles.infoNavContainer}>
                        <div className={styles.companyInfoContainer}>
                            <CompanyInfo />
                            <button className={styles.tipsOss}>
                                <Link
                                    to="/tips-oss"
                                    tracking={{
                                        category: 'footer',
                                        action: 'tips',
                                        label: '/tips-oss',
                                    }}
                                    data-ga4-event-name={'footer'}
                                    data-ga4-event-details-1={'colophon'}
                                    data-ga4-event-details-2={'colophon'}
                                    data-ga4-link-title={'Tips oss'}
                                    data-ga4-link-url={'/tips-oss'}
                                    isExternal={false}
                                >
                                    Tips oss
                                </Link>
                            </button>
                        </div>
                        <div className={styles.navigationContainer}>
                            <div className={styles.desktopNavigation}>
                                <Navigation
                                    navigationCategories={navigationCategories}
                                />
                            </div>
                            <div className={styles.mobileNavigation}>
                                <MobileNavigation
                                    navigationCategories={navigationCategories}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className={`${styles.informationContainer} ${
                        styles.footerBox
                    }`}
                >
                    <div
                        className={styles.socialContainer}
                        data-ga4-event-details-1="social_icons"
                    >
                        <Link
                            to="https://www.facebook.com/Startsiden"
                            className={styles.facebook}
                            tracking={{
                                category: 'footer',
                                action: 'navigation',
                                label: 'facebook',
                            }}
                            isExternal={true}
                        >
                            <img
                                className={styles.brandImg}
                                src={Facebook}
                                alt="Facebook"
                            />
                        </Link>
                    </div>
                    <div
                        className={styles.infoBox}
                        data-ga4-event-details-1="contact"
                    >
                        <div
                            className={styles.contactInfo}
                            data-ga4-event-details-2="informasjon_og_kontakt"
                        >
                            <h3 className={styles.columnTitle}>
                                Informasjon og kontakt
                            </h3>
                            {renderLinks(
                                urlsInfo,
                                'informasjon_og_kontakt',
                                showSPBtn
                            )}
                        </div>
                        <div
                            className={styles.otherInfo}
                            data-ga4-event-details-2="salg"
                        >
                            <h3 className={styles.columnTitle}>Salg</h3>
                            {renderLinks(urlsSalg, 'salg', showSPBtn)}
                        </div>
                    </div>
                </div>

                <div
                    className={`${styles.linksContainer} ${styles.footerBox}`}
                    data-ga4-event-details-1="internal_products_links"
                >
                    <ul className={styles.topLinks}>
                        {mainLinks.map(link => renderLogos(link))}
                    </ul>
                </div>

                <div
                    className={styles.copyrightContainer}
                    data-ga4-event-details-1="copyright_container"
                >
                    <div>
                        Copyright © ABC Startsiden AS - ABC Nyheter er en del
                        av&ensp;
                        <Link
                            to={urlCompany}
                            className={styles.colorLink}
                            tracking={{
                                category: 'footer',
                                action: 'navigation',
                                label: 'abc_startsiden',
                            }}
                            isExternal={true}
                        >
                            ABC Startsiden AS
                        </Link>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
