const isFetchDefined = typeof window.fetch !== 'undefined';
const isSymbolDefined = typeof Symbol !== 'undefined';
const isObjectAssignDefined = typeof Object.assign === 'function';
const shouldLoadIE11Polyfill =
    !isFetchDefined || !isSymbolDefined || !isObjectAssignDefined;
const shouldLoadIntersectionObserver =
    !('IntersectionObserver' in window) &&
    !('IntersectionObserverEntry' in window);

// prettier-ignore
const polyfillsMap = [
    {
        condition: shouldLoadIE11Polyfill,
        load: () => {
            return Promise.all([
                import(/* webpackChunkName: "polyfills" */ 'react-app-polyfill/ie11'),

                // @ts-ignore core-js typings are not yet adjusted to latest core-js version
                import(/* webpackChunkName: "polyfills" */ 'core-js/features/array'),

                // @ts-ignore core-js typings are not yet adjusted to latest core-js version
                import(/* webpackChunkName: "polyfills" */ 'core-js/features/string'),

                // @ts-ignore
                import(/* webpackChunkName: "polyfills" */ '@startsiden/vipr-player/dist/polyfill'),

                // @ts-ignore
                import(/* webpackChunkName: "polyfills" */ 'core-js/features/url'),
                // @ts-ignore
                import(/* webpackChunkName: "polyfills" */ 'core-js/features/reflect'),
            ])
        },
    },
    {
        condition: !Array.prototype.fill,
        load: () => {
            // @ts-ignore
            return import(/* webpackChunkName: "polyfills" */ 'core-js/features/array/fill');
        },
    },
    {
        condition: !Array.prototype.find,
        load: () => {
            // @ts-ignore
            return import(/* webpackChunkName: "polyfills" */ 'core-js/features/array/find');
        },
    },
    {
        condition: !Object.values,
        load: () => {
            // @ts-ignore
            return import(/* webpackChunkName: "polyfills" */ 'core-js/features/object/values');
        },
    },
    {
        condition: shouldLoadIntersectionObserver,
        load: () => {
            // @ts-ignore
            return import(/* webpackChunkName: "polyfills" */ 'intersection-observer');
        },
    },
];

const polyfillPromises = polyfillsMap
    .filter(item => !!item.condition)
    .map(item => item.load());

export default polyfillPromises;
