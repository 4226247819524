import React, { Component } from 'react';
import intersectionObserver from 'src/services/IntersectionObserverProvider';

export interface IInViewProps {
    options: IntersectionObserverInit;
    className?: string;
}
export interface IInViewState {
    isInView: boolean;
    observedElement?: Element;
}

export default class InView extends Component<IInViewProps, IInViewState> {
    public static defaultProps = {
        options: {
            root: null,
            rootMargin: '0px',
            threshold: 0,
        },
    };

    public state = {
        isInView: false,
    } as IInViewState;

    public render() {
        return (
            <div ref={this.saveRef} className={this.props.className}>
                {this.state.isInView ? this.props.children : null}
            </div>
        );
    }

    private observe = () => {
        if (this.state.observedElement) {
            intersectionObserver.observe({
                callback: this.onViewportEnter,
                options: this.props.options,
                target: this.state.observedElement,
            });
        }
    };

    private onViewportEnter = () => {
        this.setState(
            () => ({
                isInView: true,
            }),
            () => this.unobserve()
        );
    };

    private unobserve = () => {
        intersectionObserver.unobserve(this.state.observedElement);
    };

    private saveRef = (observedElement: HTMLDivElement) => {
        this.setState({ observedElement }, this.observe);
    };
}
