import format from 'date-fns/format';
import nbLocale from 'date-fns/locale/nb';

const capitalizeFirstLetter = (str: string) =>
    `${str.charAt(0).toUpperCase()}${str.substr(1)}`;

export const formatDuration = (durationInSeconds: number) => {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = Math.floor(durationInSeconds % 60);

    return [
        hours,
        minutes > 9 ? minutes : hours ? '0' + minutes : minutes || '0',
        seconds > 9 ? seconds : '0' + seconds,
    ]
        .filter(a => a)
        .join(':');
};

export const formatDateTime = (unixEpochTime: number) => {
    const timestamp = unixEpochTime * 1000;
    const formattedDateString = format(timestamp, 'dd. MMM yyyy HH:mm', {
        locale: nbLocale,
    });
    return capitalizeFirstLetter(formattedDateString);
};

export const formatDate = (unixEpochTime: number) => {
    const timestamp = unixEpochTime * 1000;
    const formattedDateString = format(timestamp, 'dd. MMM yyyy', {
        locale: nbLocale,
    });
    return capitalizeFirstLetter(formattedDateString);
};

export const formatDateNumber = (unixEpochTime: number) => {
    const timestamp = unixEpochTime * 1000;
    const formattedDateString = format(timestamp, 'dd-MM-yyyy', {
        locale: nbLocale,
    });
    return formattedDateString;
};

export const formatIsoDate = (unixEpochTime: number) => {
    const timestamp = unixEpochTime * 1000;
    const formattedDateString = format(
        timestamp,
        'EEE, dd MMM yyyy HH:mm:ss xx'
    );
    return capitalizeFirstLetter(formattedDateString);
};

export default {
    formatDate,
    formatDateTime,
    formatDuration,
    formatIsoDate,
};
