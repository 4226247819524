class Tracking {
    public async execute(trackingUrl: string) {
        const response = await fetch(trackingUrl);
        return this.parseResponse({ response });
    }

    public parseResponse({ response }: { response: any }) {
        return response.status === 200;
    }
}

const tracking = new Tracking();

export default tracking;
