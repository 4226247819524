import React, { Component, memo, ReactNode } from 'react';
import Loader from 'src/components/Loader';
import {
    GET_CATEGORY_LATEST,
    GET_CATEGORY_POPULAR,
    GET_FEATURED_VIDEOS,
    GET_LATEST_VIDEOS,
    GET_POPULAR_VIDEOS,
} from 'src/gql';
import concatClassNames from 'src/helpers/concatClassNames';
import { IVideoListProps } from 'src/types/videoList';
import generateVideoListWithDisplayAds from './displayAd';
import generateVideoList from './generateVideoList';
import styles from './VideoList.module.scss';

class VideoList extends Component<IVideoListProps, any> {
    private videoList: ReactNode | ReactNode[] = [];

    public render() {
        const {
            loading,
            title = '',
            videoList = [],
            listItemVariant,
            onClick,
            listType,
            displayAd,
            tracking,
            selectedVideoId,
            showLabelBeforeData = true,
            loaderCount = 0,
            showPublished,
            query = '',
        } = this.props;

        const className = concatClassNames(styles.videoList, styles[listType]);
        this.videoList = generateVideoList({
            videoList,
            listItemVariant,
            onClick,
            tracking,
            title,
            selectedVideoId,
            showPublished,
        });
        if (loading !== undefined && Array.isArray(this.videoList)) {
            this.videoList = this.addLoadingWrappers(
                this.videoList,
                loading,
                loaderCount
            );
        }

        this.videoList = generateVideoListWithDisplayAds({
            videoList: this.videoList,
            displayAd,
        });

        return (
            <div className={className}>
                {title &&
                    (showLabelBeforeData &&
                        (videoList && videoList.length > 0)) && (
                        <header className={styles.header}>
                            <span className={styles.headerLogo}>
                                {this.setLogo(query)}
                            </span>
                            <h4 className={styles.headerTitle}>{title}</h4>
                        </header>
                    )}
                <div className={styles[`listWrapper--${listItemVariant}`]}>
                    <div className={styles.list}>{this.videoList}</div>
                </div>
            </div>
        );
    }

    private setLogo = (query: string) => {
        switch (query) {
            case GET_CATEGORY_POPULAR:
            case GET_POPULAR_VIDEOS:
            case GET_FEATURED_VIDEOS:
                return '👍🏼';
            case GET_CATEGORY_LATEST:
            case GET_LATEST_VIDEOS:
                return '🔥';
            default:
                return '';
        }
    };

    private addLoadingWrappers(
        videoList: ReactNode[],
        loading: boolean,
        loaderCount: number
    ): ReactNode[] {
        const {
            error,
            replaceChildrenWhenLoading,
            listItemVariant,
            listType,
        } = this.props;
        let i;
        for (i = 0; i < loaderCount; i++) {
            videoList.push(
                <Loader
                    key={`videoListLoader-${i}`}
                    containerClass={listItemVariant}
                    listClass={listType}
                    loaderCount={1}
                    loading={loading}
                    error={error}
                    replaceChildrenWhenLoading={replaceChildrenWhenLoading}
                    withWrapper={false}
                />
            );
        }
        return videoList;
    }
}

export default memo(VideoList);
