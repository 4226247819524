import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { RouteComponentProps } from 'react-router-dom';

import { useQuery } from '@apollo/react-hooks';
import AdSlot from 'src/components/Ads/AdSlot/AdSlot';
import Loader from 'src/components/Loader';
import Video from 'src/components/Video';
import VideoList from 'src/components/VideoList';
import CategoryContext from 'src/context/CategoryContext';
import PlayListContext from 'src/context/PlayListContext';
import { GET_PLAYLIST_VIDEO, GET_URL } from 'src/gql';
import { GET_IS_FIRST_LOAD } from 'src/gql/queries/@clientQueries';
import { getSharingImageProxyUrl } from 'src/helpers/imageProxy/imageProxy';
import useGraphqlQuery from 'src/helpers/readGraphqlQuery/useGraphqlQuery';
import urlProvider from 'src/helpers/urlProvider/urlProvider';
import tracking from 'src/services/Tracking';
import { IVideo } from 'src/types/video';
import styles from './PlayList.module.scss';

interface IPlayListRouteProps
    extends RouteComponentProps<{
        playListId: string;
        videoId: string;
        urlSafeTitle: string;
    }> {}

function PlayListRoute(props: IPlayListRouteProps) {
    const { location, match } = props;
    const { playListId, videoId } = match.params;
    const [videoToTrack, setVideoToTrack] = useState<IVideo>();
    const [playedVideo, setPlayedVideo] = useState<IVideo>();

    const { loading, data, error } = useQuery(GET_PLAYLIST_VIDEO, {
        variables: {
            id: playListId,
            limit: 500,
        },
        notifyOnNetworkStatusChange: true,
    });

    if (error) {
        throw error;
    }

    const { videos } = data;
    const { isFirstLoad } = useGraphqlQuery(GET_IS_FIRST_LOAD);
    const { urlRef } = useGraphqlQuery(GET_URL);

    useEffect(() => {
        if (videoToTrack) {
            tracking.trackPageView({
                location: window.location,
                title: videoToTrack.title,
                video: videoToTrack,
                urlref: (urlRef && urlRef[1]) || 'direct',
                isFirstLoad,
            });
        }
    }, [videoToTrack]);

    useEffect(() => {
        if (playedVideo && !loading) {
            setVideoToTrack(playedVideo);
        }
    }, [playedVideo]);

    useEffect(() => {
        if (videos && !loading) {
            setPlayedVideo(
                !videoId
                    ? videos.items[0]
                    : videos.items.find(
                          (video: IVideo) => video.provider_id === videoId
                      )
            );
        }
    }, [videos, videoId, data]);
    return (
        <main>
            <div className={styles.container}>
                <div className={styles.main}>
                    {playedVideo && (
                        <Helmet
                            title={playedVideo.title}
                            meta={[
                                {
                                    name: 'description',
                                    content: playedVideo.summary,
                                },
                                {
                                    property: 'og:description',
                                    content: playedVideo.summary,
                                },
                                {
                                    property: 'og:title',
                                    content: playedVideo.title,
                                },
                                {
                                    property: 'og:image',
                                    content: getSharingImageProxyUrl(
                                        playedVideo.imageUrl
                                    ),
                                },
                                {
                                    property: 'og:image:width',
                                    content: '1200',
                                },
                                {
                                    property: 'og:image:height',
                                    content: '628',
                                },
                                {
                                    property: 'og:url',
                                    content: urlProvider(location.pathname),
                                },
                            ]}
                        />
                    )}
                    <Loader
                        containerClass="video"
                        listClass="vertical"
                        loaderCount={1}
                        loading={loading}
                        error={error}
                    >
                        {playedVideo && !loading && (
                            <Video
                                video={playedVideo}
                                playListId={playListId}
                            />
                        )}
                    </Loader>
                </div>
                <AdSlot id="topbanner-mob" className="topbannerMob" />
                <aside className={styles.sidebar}>
                    <CategoryContext.Provider value={{ name: 'play' }}>
                        <PlayListContext.Provider value={{ id: playListId }}>
                            <VideoList
                                title="Neste video"
                                listItemVariant="horizontal-scroll"
                                videoList={videos && videos.items}
                                listType="vertical-scroll"
                                selectedVideoId={
                                    playedVideo && playedVideo.provider_id
                                }
                            />
                        </PlayListContext.Provider>
                    </CategoryContext.Provider>
                </aside>
            </div>
        </main>
    );
}

export default PlayListRoute;
