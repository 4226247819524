import gql from 'graphql-tag';
import { ItemsFragment } from './fragments';

export const GET_LATEST_VIDEOS = gql`
    query GetLatestVideos($limit: Int!, $offset: Int) {
        videoList: videos(offset: $offset, limit: $limit) {
            ...baseVideoFields
        }
    }
    ${ItemsFragment}
`;
