/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react';

import Arrow from 'src/components/Footer/logos/Arrow.svg';
import RedArrowRight from 'src/components/Footer/logos/RedArrowRight.svg';
import { categoryUrl } from 'src/helpers/linkCreator/linkCreator';
import { Link } from '../Link';
import { INavigationCategory } from './Footer';
import styles from './styles/Accordion.module.scss';

interface IAccordionProps {
    navigationCategories: INavigationCategory[];
    activeItem: number;
    onClick: (index: number, isOpen: boolean) => void;
}

const Accordion: FC<IAccordionProps> = props => {
    const { navigationCategories, activeItem = -1, onClick } = props;

    const renderCategory = (category: INavigationCategory, index: number) => {
        const isOpen = index === activeItem;
        const contentClassname = `${styles.content} ${
            isOpen ? '' : styles.isCollapsed
        }`;
        const onItemClick = () => onClick(index, isOpen);
        const url = categoryUrl(category.urlname);
        const isMer = category.urlname === 'mer';
        return (
            <div
                className={`${isOpen ? styles.active : ''}`}
                key={`${index}-${category.text}`}
                onClick={onItemClick}
            >
                <div className={styles.title}>
                    {category.text}
                    <img className={styles.arrow} src={Arrow} alt="Arrow" />
                </div>
                <div className={contentClassname} key={index}>
                    <ul>
                        {category.subcategories &&
                            category.subcategories.map(subCategory =>
                                renderItem(subCategory, url)
                            )}
                        {!isMer && (
                            <li className={styles.categoryButton}>
                                <Link
                                    to={url}
                                    tracking={{
                                        category: 'footer',
                                        action: 'menu',
                                        label: `${category.text}`,
                                    }}
                                    isExternal={true}
                                >
                                    Gå til {category.text}
                                </Link>
                                <img
                                    className={styles.redArrow}
                                    src={RedArrowRight}
                                    alt="Red Arrow"
                                />
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        );
    };

    const renderItem = (subCategory: string, url: string) => {
        return (
            <li className={styles.itemName} key={subCategory}>
                <Link
                    to={`${url}/${subCategory}`}
                    tracking={{
                        category: 'footer',
                        action: 'menu',
                        label: `${subCategory}`,
                    }}
                    isExternal={true}
                >
                    {subCategory}
                </Link>
            </li>
        );
    };

    return (
        <div>
            {navigationCategories.map((category, index) =>
                renderCategory(category, index)
            )}
        </div>
    );
};

export default Accordion;
