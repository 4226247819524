/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import Facebook from 'src/components/Footer/logos/Facebook.svg';
import TrustIcon from 'src/components/Footer/logos/TrustIcon.svg';
import { Link } from '../Link';
import styles from './styles/CompanyInfo.module.scss';

export default function CompanyInfo() {
    return (
        <div
            data-ga4-event-name={'footer'}
            data-ga4-event-details-1={'colophon'}
            data-ga4-event-details-2={'colophon'}
        >
            <img
                className={styles.facebookLogo}
                src={Facebook}
                alt="Facebook"
            />
            <div className={styles.companyInfoSection}>
                Adm. dir. og ansvarlig redaktør:{' '}
                <Link
                    to="mailto:espen.udland@startsiden.no"
                    tracking={{
                        category: 'footer',
                        action: 'colophone',
                        label: 'admdir',
                    }}
                    data-ga4-link-title={'admdir'}
                    data-ga4-link-url={'mailto:espen.udland@startsiden.no'}
                    isExternal={true}
                >
                    Espen Udland
                </Link>
            </div>
            <div className={styles.companyInfoSection}>
                Redaktør:{' '}
                <Link
                    to="mailto:david.stenerud@abcnyheter.no"
                    tracking={{
                        category: 'footer',
                        action: 'colophone',
                        label: 'redaktor',
                    }}
                    data-ga4-link-title={'redaktor'}
                    data-ga4-link-url={'mailto:david.stenerud@abcnyheter.no'}
                    isExternal={true}
                >
                    David Stenerud
                </Link>
            </div>
            <div className={`${styles.companyInfoSection} ${styles.withTrust}`}>
                <div>
                    ABC Nyheter arbeider etter{' '}
                    <Link
                        to="https://www.nored.no/Redaktoeransvar/Redaktoerplakaten"
                        tracking={{
                            category: 'footer',
                            action: 'colophone',
                            label:
                                'https://www.nored.no/Redaktoeransvar/Redaktoerplakaten',
                        }}
                        data-ga4-link-title={
                            'https://www.nored.no/Redaktoeransvar/Redaktoerplakaten'
                        }
                        data-ga4-link-url={
                            'https://www.nored.no/Redaktoeransvar/Redaktoerplakaten'
                        }
                        isExternal={true}
                    >
                        Redaktørplakaten
                    </Link>{' '}
                    og{' '}
                    <Link
                        to="https://presse.no/pfu/etiske-regler/vaer-varsom-plakaten/"
                        tracking={{
                            category: 'footer',
                            action: 'colophone',
                            label:
                                'https://presse.no/pfu/etiske-regler/vaer-varsom-plakaten/',
                        }}
                        data-ga4-link-title={
                            'https://presse.no/pfu/etiske-regler/vaer-varsom-plakaten/'
                        }
                        data-ga4-link-url={
                            'https://presse.no/pfu/etiske-regler/vaer-varsom-plakaten/'
                        }
                        isExternal={true}
                    >
                        Vær Varsom-plakatens
                    </Link>{' '}
                    retningslinjer for god presseskikk.
                </div>
                <Link
                    to="https://www.nored.no/Redaktoeransvar/Redaktoerplakaten"
                    className={styles.trustIcon}
                    tracking={{
                        category: 'footer',
                        action: 'trust-icon',
                        label:
                            'https://www.nored.no/Redaktoeransvar/Redaktoerplakaten',
                    }}
                    data-ga4-link-title={'trust-icon'}
                    data-ga4-link-url={
                        'https://www.nored.no/Redaktoeransvar/Redaktoerplakaten'
                    }
                    isExternal={true}
                >
                    <img src={TrustIcon} alt="Trust Icon" />
                </Link>
            </div>
        </div>
    );
}
