import 'cross-fetch/polyfill';

import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import getConfig from 'src/config';
import isBrowser from 'src/helpers/isBrowser';

const initialCache = isBrowser() ? (window as any).__APOLLO_STATE__ : {};
const client = new ApolloClient({
    cache: new InMemoryCache().restore(initialCache),
    link: createHttpLink({
        uri: getConfig().api,
    }),
    resolvers: {},
});

export default client;
